import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader } from "../../components";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditFilled,
} from "@ant-design/icons";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetShopsQuery,
  useDeleteShopMutation,
  usePutUpdateShopsMutation,
} from "../../services/shops";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, shop } from "../../types/General";
import useAuth from "../../hooks/useAuth";

const { confirm } = Modal;

type PropTypes = {
  scrollPosition: ScrollPosition;
};
const ManageShops = ({ scrollPosition }: PropTypes) => {
  const navigate = useNavigate();
  const [shopList, shopListQuery] = useLazyGetShopsQuery();
  const [updateShop] = usePutUpdateShopsMutation();
  const [deleteShop] = useDeleteShopMutation();
  const [shopDetails, setShopDetails] = useState<shop[]>();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this user?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteShop({ shopId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Shop deleted successfully");
        getShopDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const activeInactiveCategory = async (isBlocked: boolean, id: string) => {
    const data = {
      isBlocked: isBlocked,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await updateShop({
        encryptedBody,
        shopId: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        successToast(
          isBlocked
            ? "Shop Deactivated Successfully!"
            : "Shop Activated Successfully!"
        );
        getShopDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const getShopDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await shopList({
        page: page,
        search: query || "",
      }).unwrap();
      setLoading(false);

      setShopDetails(res?.data?.shop);

      if (res?.statusCode === 200) {
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.label === "Manage Shops");
      if (idx != -1) {
        if (!permissions[idx]?.isAdd) {
          let data = {
            isAdd: false,
            isDelete: hidePermission?.isDelete,
          };
          setHidePermission(data);
        }
        if (!permissions[idx]?.isDelete) {
          let data = {
            isAdd: hidePermission?.isAdd,
            isDelete: false,
          };
          setHidePermission(data);
        }
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const columns: ColumnsType<shop> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (_, { images }: any) => (
        <div>
          {images ? (
            <LazyLoadImage
              alt="img"
              style={{ height: 70, width: 70, borderRadius: 100 }}
              src={images[0]}
              effect="blur"
              scrollPosition={scrollPosition}
            />
          ) : (
            "N/A"
          )}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-shops/details/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/manage-shops/edit/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : (
            ""
          )}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => activeInactiveCategory(!isBlocked, _id)}
        />
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getShopDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  useEffect(() => {
    getShopDetails(1);
  }, [query]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Manage Shops"
        // buttonNavigateTo="manageusers/add"
      />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Form style={{ display: "flex", gap: 20 }}>
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  value={debouncedQuery}
                  onChange={(e) => setDebouncedQuery(e.target.value)}
                  maxLength={20}
                />
              </Form>
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            ></Col>
          </Row>
          <Table
            columns={columns}
            dataSource={shopDetails}
            loading={loading}
            pagination={pagination}
            rowKey="_id"
          />
        </Card>
      </div>
    </div>
  );
};

export default trackWindowScroll(ManageShops);
