import { END_POINTS } from "../helpers";
import {
  CommonBody,
  Donation,
  DonationBooking,
  FundBooking,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  data?: {
    count: number;
    pages: number;
    message: string;
    donationAdmin: Donation[] | [];
  };
  message: string;
};

type BookingResponse = {
  statusCode: number;
  message: string;
};

type PutUserParams = {
  organizationId: string | undefined;
  encryptedBody: CommonBody;
};

export const donationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDonationAdminDetails: builder.query<
      CommonResponse,
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.donationAdmin +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    getOrganizationDetailsId: builder.query<
      CommonResponse & { data: any },
      string | undefined
    >({
      query: (organizationId) => ({
        url: `${END_POINTS.donationAdmin}/${organizationId}`,
        method: "GET",
      }),
    }),

    postAddOrganization: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.donationAdmin,
        method: "POST",
        body,
      }),
    }),

    deleteOrganization: builder.mutation<
      CommonResponse,
      { organizationId: string }
    >({
      query: ({ organizationId }) => ({
        url: `${END_POINTS.donationAdmin}/${organizationId}`,
        method: "DELETE",
      }),
    }),

    putUpdateOrganizationRequest: builder.mutation<
      CommonResponse,
      PutUserParams
    >({
      query: ({ encryptedBody, organizationId }) => ({
        url: `${END_POINTS.donationAdmin}/${organizationId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    putBlockOrganization: builder.mutation<CommonResponse, PutUserParams>({
      query: ({ encryptedBody, organizationId }) => ({
        url: `${END_POINTS.donationAdmin}/${organizationId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    putUpdateOrganization: builder.mutation<CommonResponse, PutUserParams>({
      query: ({ encryptedBody, organizationId }) => ({
        url: `${END_POINTS.donationAdmin}/${organizationId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    getOrganizationCsv: builder.query<CommonResponse & { data: any }, {}>({
      query: ({}) => ({
        url: `${END_POINTS.donationAdminsExports}`,
        method: "GET",
      }),
    }),

    getDonationBooking: builder.query<
      BookingResponse & {
        data: {
          count: number;
          pages: number;
          message: string;
          booking: DonationBooking[] | [];
        };
      },
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.donationBooking +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    getDonationBookingById: builder.query<
      CommonResponse & { data: DonationBooking },
      string | undefined
    >({
      query: (donationId) => ({
        url: `${END_POINTS.donationBooking}/${donationId}`,
        method: "GET",
      }),
    }),

    getFundRaisingBooking: builder.query<
      BookingResponse & {
        data: any;
      },
      { eventId: string; page: number; size: number; search: string }
    >({
      query: ({ eventId, page, size, search }) => ({
        url:
          END_POINTS.fundRaisingBooking +
          "?eventId=" +
          eventId +
          "&page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    getFundRaisingBookingById: builder.query<
      CommonResponse & { data: FundBooking },
      string | undefined
    >({
      query: (donationId) => ({
        url: `${END_POINTS.fundRaisingBooking}/${donationId}`,
        method: "GET",
      }),
    }),

    getDonationBookingCsv: builder.query<CommonResponse & { data: any }, {}>({
      query: ({}) => ({
        url: `${END_POINTS.donationBookingExport}`,
        method: "GET",
      }),
    }),

    getFundRaisingEvent: builder.query<
      BookingResponse & {
        data: any;
      },
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.fundRaising +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    getFundRaisingEventById: builder.query<
      CommonResponse & { data: any },
      string | undefined
    >({
      query: (donationId) => ({
        url: `${END_POINTS.fundRaising}/${donationId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetDonationAdminDetailsQuery,
  useLazyGetOrganizationDetailsIdQuery,
  useDeleteOrganizationMutation,
  usePutUpdateOrganizationRequestMutation,
  usePutBlockOrganizationMutation,
  usePostAddOrganizationMutation,
  usePutUpdateOrganizationMutation,
  useLazyGetOrganizationCsvQuery,
  useLazyGetDonationBookingQuery,
  useLazyGetDonationBookingByIdQuery,
  useLazyGetFundRaisingBookingQuery,
  useLazyGetFundRaisingBookingByIdQuery,
  useLazyGetDonationBookingCsvQuery,
  useLazyGetFundRaisingEventQuery,
  useLazyGetFundRaisingEventByIdQuery,
} = donationApi;
