import { Button, Card, Col, Row, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetDonationBookingByIdQuery } from "../../../services/donation";
import { DonationBooking } from "../../../types/General";

const DonarDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [DonationBookingById] = useLazyGetDonationBookingByIdQuery();

  const [loading, setLoading] = useState(false);
  const [donationBookingDetails, setDonationBookingDetails] =
    useState<DonationBooking>();

  const getDonationBookingDetailsById = async () => {
    try {
      setLoading(true);
      const res = await DonationBookingById(id).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setDonationBookingDetails(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDonationBookingDetailsById();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Donar Details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/donations", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}></Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Donar Name</h4>
                <h5>{donationBookingDetails?.name || "-"}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Campaign Name</h4>
                <h5>{donationBookingDetails?.donationId?.name || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Donated Date</h4>
                <h5>
                  {moment(donationBookingDetails?.createdAt).format(
                    "MMM Do YYYY"
                  ) || "-"}
                </h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Donated Amount</h4>
                <h5>
                  {donationBookingDetails?.amount
                    ? "$" + donationBookingDetails?.amount
                    : "$0"}{" "}
                </h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Contact</h4>
                <h5>
                  {`${donationBookingDetails?.user?.countryCode || ""} ${
                    donationBookingDetails?.user?.phone || "-"
                  }`}
                </h5>
              </Col>
            </Row>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default DonarDetails;
