import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";
import { Reviews } from "../../types/General";
import { useDeleteReviewMutation } from "../../services/support";
import { errorToast, successToast } from "../../helpers";
import ReplyToPostModal from "../../components/replyToPostModal/ReplyToPostModal";

const { confirm } = Modal;
interface DataType {
  key: string;
  title: string;
  description: string;
  customerName: string;
  date: string;
  email: string;
  createdAt: string;
}

type Permission = {
  isAdd: boolean;
  isDelete: boolean;
};

type PropsConst = {
  hidePermission: Permission;
  allReview: Reviews[] | [];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalCount: number | undefined;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  getAllReviewsData: (page: number) => void;
};

const Queries = ({
  hidePermission,
  allReview,
  setCurrentPage,
  setLoading,
  totalCount,
  setQuery,
  query,
  getAllReviewsData,
  currentPage,
  loading,
}: PropsConst) => {
  const [deleteReview] = useDeleteReviewMutation();

  const [debouncedQuery, setDebouncedQuery] = useState("");

  const columns: ColumnsType<Reviews> = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width: "10%",
      align: "center",
      render: (_, { userId }) => (
        <div>
          <span>{userId?.fullName}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      align: "center",
      render: (_, { userId }) => (
        <div>
          <span>{userId?.email}</span>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: "15%",
      align: "center",
      render: (_, { createdAt }) => (
        <div>
          <span>{moment(createdAt).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      width: "15%",
      align: "center",
      render: (record) => (
        <Space size="middle">
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : null}

          <ReplyToPostModal id={record?._id} />
        </Space>
      ),
    },
  ];

  const showDeleteConfirm = (record: Reviews) => {
    confirm({
      title: "Hold on",
      content: "Are you sure you want to delete this query?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteReview({ reviewId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Review deleted successfully");
        getAllReviewsData(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllReviewsData(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  return (
    <div>
      <Row style={{ paddingTop: 5, paddingBottom: 20 }} gutter={16}>
        <Col xs={24} md={7}>
          <Form
            style={{ display: "flex", gap: 20 }}
            onFinish={() => getAllReviewsData(1)}
          >
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
              style={{ height: 50, borderRadius: 10 }}
              value={debouncedQuery}
              onChange={(e) => setDebouncedQuery(e.target.value)}
              maxLength={20}
            />
          </Form>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={24}>
          <Table
            columns={columns}
            dataSource={allReview}
            rowKey="_id"
            pagination={pagination}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Queries;
