import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { ContentHeader, MultipleImages } from "../../../components";
import { RcFile } from "antd/es/upload";
import { CloseCircleOutlined } from "@ant-design/icons";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody, category } from "../../../types/General";
import {
  usePutUpdateProductMutation,
  useLazyGetProductByIdQuery,
} from "../../../services/product";
import { useLazyGetCategoryQuery } from "../../../services/category";

const ProductForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [updateProduct] = usePutUpdateProductMutation();

  const [getProductDataById] = useLazyGetProductByIdQuery();
  const [imageObject, setImageObject] = useState<RcFile[] | number[] | any>([]);
  const [imagePreview, setImagePreview] = useState<string[]>([]);

  const [getCategory] = useLazyGetCategoryQuery();
  const [allCategoryData, setAllCategoryData] = useState<category[]>();

  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [getImages, setGetImages] = useState([]);
  const { Option } = Select;

  const getProductDetailById = async () => {
    try {
      const res = await getProductDataById(id).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          image: res?.data?.image,
          productName: res?.data?.name,
          priceOfTheProduct: res?.data?.price,
          categoryOfTheProduct: res?.data?.categoryId?._id,
          addressOfTheUser: res?.data?.address,
        });
        setProductName(res?.data?.name);
        setPrice(res?.data?.price);
        setSelectedCategory(res?.data?.categoryId?._id);
        setAddress(res?.data?.address);
        setGetImages(res?.data?.images);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleImageDelete = (index: number) => {
    let previewImages = imagePreview.filter((_, i) => i !== index);
    setImagePreview(previewImages);
    let objectImages = (imageObject as any).filter(
      (_: any, i: number) => i !== index
    );
    setImageObject(objectImages as any);
  };

  const handleGetImageDelete = (index: number) => {
    let previewImages = getImages.filter((_, i) => i !== index);
    setGetImages(previewImages);
  };

  const handleImageUpload = async (file: RcFile) => {
    setLoading(true);
    try {
      const res = await UploadMedia(file);
      setLoading(false);
      if (res?.statusCode === 200) {
        return res?.data;
      }
      return 0;
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      return 0;
    }
  };

  const onFinish = async (values: any) => {
    if (!imageObject?.length && !getImages?.length) {
      errorToast("Please provide images/videos for product.");

      return;
    }

    let images = [] as any;
    let urls = [] as any;
    if (imageObject?.length) {
      await Promise.all(
        imageObject?.map(async (item: RcFile) => {
          if (typeof item !== "number") {
            let result = await handleImageUpload(item);
            console.log(result);
            images?.push({
              image: result,
            });
          } else {
            images?.push({
              image: item,
            });
          }
        })
      );
      urls = images.map((item: any) => item.image);
    }

    const data = {
      name: values?.productName,
      images: Array.from(getImages).concat(urls),
      price: values?.priceOfTheProduct,
      address: values?.addressOfTheUser,
      categoryId: selectedCategory || "",
    };
    console.log(data, "cdata");

    try {
      let body = generateEncryptedKeyBody(data) as CommonBody;

      const res = await updateProduct({
        body,
        productId: id,
      }).unwrap();

      if (res?.statusCode === 200) {
        navigate("/listed-products-posts", { replace: true });
        successToast(res?.message);
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const getCategoryDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getCategory({
        page: page,
        size: 50,
        search: "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllCategoryData(res?.data?.category);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      categoryOfTheProduct: value,
    });
    setSelectedCategory(value);

    form.validateFields(["categoryOfTheProduct"]);
  };

  useEffect(() => {
    getCategoryDetails(1);
    getProductDetailById();
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="listed-products-posts"
        heading="Add Product"
      />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            onFinish={onFinish}
            initialValues={{ remember: true }}
            // onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <h3>Add images, and videos for the product</h3>

            <Row gutter={16}>
              <Col xs={24} md={24}>
                <Row gutter={16}>
                  {getImages?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleGetImageDelete(index)}
                      className="img_contnr"
                    >
                      <CloseCircleOutlined
                        style={{
                          color: "red",
                          position: "absolute",
                          right: 4,
                          top: 4,
                          fontSize: 20,
                          cursor: "pointer",
                        }}
                      />
                      {item ? (
                        <figure>
                          {/* @ts-ignore */}
                          {item?.split(".").pop() === "mp4" ? (
                            <video muted src={item} autoPlay />
                          ) : (
                            <img alt="" src={item} />
                          )}
                        </figure>
                      ) : null}
                    </div>
                  ))}
                  {imagePreview?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleImageDelete(index)}
                      className="img_contnr"
                    >
                      <CloseCircleOutlined
                        style={{
                          color: "red",
                          position: "absolute",
                          right: 4,
                          top: 4,
                          fontSize: 20,
                          cursor: "pointer",
                        }}
                      />
                      <figure>
                        <img alt="" src={item} />
                      </figure>
                    </div>
                  ))}
                </Row>

                <div className="img_contnr2">
                  <MultipleImages
                    imageObject={imageObject}
                    setImageObject={setImageObject}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="productName"
                  rules={[
                    {
                      required: true,
                      message: "Please input Product name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Product Name</h4>
                    <Input
                      placeholder="Full Name"
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setProductName(e.target.value);
                      }}
                      value={productName}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="priceOfTheProduct"
                  rules={[
                    {
                      required: true,
                      message: "Please input Price of the Product!",
                    },
                  ]}
                >
                  <div>
                    <h4>Price of the Product</h4>
                    <Input
                      placeholder="Price of the Product"
                      style={{ height: 55 }}
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  name="categoryOfTheProduct"
                  rules={[
                    {
                      required: true,
                      message: "Please select Category of the Product!",
                    },
                  ]}
                >
                  <div>
                    <h4>Category of the Product</h4>
                    <Select
                      allowClear
                      maxLength={20}
                      className="select_type"
                      onChange={(val) => handleChangeForSelectedCategory(val)}
                      value={selectedCategory}
                      style={{ height: 75, width: "100%" }}
                      placeholder="Select Category"
                      showSearch
                      optionFilterProp="label" // Set the property to use for filtering options
                      filterOption={(input, option) =>
                        (
                          option?.label?.toString()?.toLowerCase() || ""
                        ).indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {allCategoryData?.map((item, index) => (
                        <Option
                          key={index}
                          value={item?._id}
                          label={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="addressOfTheUser"
                  rules={[
                    {
                      required: true,
                      message: "Please input Address of the User!",
                    },
                  ]}
                >
                  <div>
                    <h4>Address of the User</h4>
                    <Input
                      style={{ height: 55 }}
                      placeholder="Address of the User"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default ProductForm;
