import React from "react";
import { Button, Col, Input, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DownloadOutlined,
  SearchOutlined,
  StarFilled,
} from "@ant-design/icons";

const BattingReport = () => {
  interface DataType {
    id: number;
    user: string;
    prediction1: string;
    prediction2: any;
    prediction3: any;
    prediction4: string;
    prediction5: string;
    prediction6: string;
    prediction7: any;
    prediction8: string;
    prediction9: string;
    prediction10: string;
  }

  const data: DataType[] = [
    {
      id: 1,
      user: "John",
      prediction1: "1.3",
      prediction2: "2.8",
      prediction3: "1.3",
      prediction4: "1.3",
      prediction5: "2.8",
      prediction6: "1.3",
      prediction7: "1.3",
      prediction8: "2.8",
      prediction9: "1.3",
      prediction10: "1.3",
    },
    {
      id: 2,
      user: "Jane",
      prediction1: "2.8",
      prediction2: "1.3",
      prediction3: "1.3",
      prediction4: "1.3",
      prediction5: "2.8",
      prediction6: "1.3",
      prediction7: (
        <div>
          1.03
          <span style={{ marginLeft: 5 }}>
            <StarFilled style={{ color: "#ECAC35", fontSize: 15 }} />
          </span>
        </div>
      ),
      prediction8: "2.8",
      prediction9: "1.3",
      prediction10: "1.3",
    },
    {
      id: 3,
      user: "Jane",
      prediction1: "2.8",
      prediction2: (
        <div>
          1.03
          <span style={{ marginLeft: 5 }}>
            <StarFilled style={{ color: "#ECAC35", fontSize: 15 }} />
          </span>
        </div>
      ),
      prediction3: "1.3",
      prediction4: "1.3",
      prediction5: "2.8",
      prediction6: "1.3",
      prediction7: "1.3",
      prediction8: "2.8",
      prediction9: "1.3",
      prediction10: "1.3",
    },
    {
      id: 4,
      user: "Jane",
      prediction1: "2.8",
      prediction2: "1.3",
      prediction3: "1.3",
      prediction4: "1.3",
      prediction5: "2.8",
      prediction6: "1.3",
      prediction7: "1.3",
      prediction8: "2.8",
      prediction9: "1.3",
      prediction10: "1.3",
    },
    {
      id: 5,
      user: "Jane",
      prediction1: "2.8",
      prediction2: "1.3",
      prediction3: (
        <div>
          1.03
          <span style={{ marginLeft: 5 }}>
            <StarFilled style={{ color: "#ECAC35", fontSize: 15 }} />
          </span>
        </div>
      ),
      prediction4: "1.3",
      prediction5: "2.8",
      prediction6: "1.3",
      prediction7: "1.3",
      prediction8: "2.8",
      prediction9: "1.3",
      prediction10: "1.3",
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction1",
      key: "prediction1",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction2",
      key: "prediction2",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction3",
      key: "prediction3",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction4",
      key: "prediction4",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction5",
      key: "prediction5",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction6",
      key: "prediction6",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction7",
      key: "prediction7",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction8",
      key: "prediction8",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction9",
      key: "prediction9",
      align: "center",
      className: "tableCol",
    },
    {
      title: "Real Vs Baclena",
      dataIndex: "prediction10",
      key: "prediction10",
      align: "center",
      className: "tableCol",
    },
  ];

  return (
    <div style={{}}>
      <div className="srch">
        <Row
          style={{ justifyContent: "space-between", padding: "15px 0px" }}
          gutter={16}
        >
          <Col xs={24} md={7}>
            <Input
              placeholder="Search Users"
              prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
              style={{ height: 50, borderRadius: 10 }}
            />
          </Col>
          <Col
            style={{
              margin: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              icon={<DownloadOutlined />}
              style={{
                background: "#ECAC35",
                height: 35,
                color: "black",
              }}
              type="primary"
            >
              Export CSV
            </Button>
          </Col>
        </Row>

        <Table columns={columns} dataSource={data} rowKey="_id" />
      </div>
    </div>
  );
};

export default BattingReport;
