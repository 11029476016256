import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Upload,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import { useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
import { ContentHeader } from "../../../components";
import {
  useAddBannerMutation,
  useLazyGetBannerByIdQuery,
  useUpdateBannerMutation,
} from "../../../services/banner";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody, SubCategory, category } from "../../../types/General";
import { useLazyGetCategoryQuery } from "../../../services/category";
import { useLazyGetSubCategoryListQuery } from "../../../services/product";

const BannerForm = () => {
  const { activeTab, id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Option } = Select;

  const [image, setImage] = useState("");
  const [feature, setFeature] = useState("");
  const [title, setTitle] = useState("");
  const [allCategoryData, setAllCategoryData] = useState<category[]>();
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [allSubCategoryData, setAllSubCategoryData] = useState<SubCategory[]>();
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [addBanner] = useAddBannerMutation();
  const [updateBanner] = useUpdateBannerMutation();
  const [getBannerById] = useLazyGetBannerByIdQuery();
  const [getCategory] = useLazyGetCategoryQuery();
  const [getSubCategoryMethod] = useLazyGetSubCategoryListQuery();

  const getBannerDetailById = async () => {
    try {
      const res = await getBannerById({
        bannerId: id as string,
        activeTab: activeTab === "1" ? 1 : activeTab === "2" ? 2 : 3,
      }).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          image: res?.data?.image,
          title: res?.data?.title || "",
          feature: res?.data?.feature || "",
          categoryOfTheProduct: res?.data?.subCategory?.parent || "",
          subCategoryField: res?.data?.subCategory?._id || "",
        });

        setImage(res?.data?.image);
        setFeature(res?.data?.feature);
        setTitle(res?.data?.title);
        setSelectedCategory(res?.data?.subCategory?.parent);
        setSelectedSubCategory(res?.data?.subCategory?._id);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const data = {
      title: values.title,
      image: image,
      bannerType: activeTab === "1" ? 1 : activeTab === "2" ? 2 : 3,
      ...(activeTab === "1" ? { feature: values.feature } : {}),
      ...(activeTab === "1" ? { subCategoryId: selectedSubCategory } : {}),
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      let res;
      id === "add"
        ? (res = await addBanner(encryptedBody).unwrap())
        : (res = await updateBanner({
            encryptedBody,
            bannerId: id ? id : "",
            activeTab: activeTab === "1" ? 1 : activeTab === "2" ? 2 : 3,
          }).unwrap());

      if (res?.statusCode === 200) {
        navigate("/manage-banners", { replace: true });
        successToast(
          id === "add"
            ? "Banner added successfully"
            : "Banner updated successfully"
        );
      } else {
        // errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleImageUpload = async (file: RcFile) => {
    if (activeTab === "1" && file?.size < 130648) {
      errorToast("Banner image minimum dimensions must be 498×540");
    } else if (
      (activeTab === "2" || activeTab === "3") &&
      file?.size < 794996
    ) {
      errorToast("Banner image minimum dimensions must be 2340×618 ");
    } else if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false; // Prevent Ant Design from automatically uploading the file
  };

  const getCategoryDetails = async () => {
    try {
      setLoading(true);
      const res = await getCategory({
        page: 1,
        size: 50,
        search: "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllCategoryData(res?.data?.category);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getSubCategoryDetails = async () => {
    try {
      setLoading(true);
      const res = await getSubCategoryMethod({
        page: 1,
        size: 50,
        search: "",
        categoryId: selectedCategory,
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllSubCategoryData(res?.data?.subCategory);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      categoryOfTheProduct: value,
    });
    setSelectedCategory(value ? value : undefined);

    form.validateFields(["categoryOfTheProduct"]);
  };

  const handleChangeForSelectedSubCategory = (value: any) => {
    form.setFieldsValue({
      subCategoryField: value,
    });
    setSelectedSubCategory(value ? value : undefined);

    form.validateFields(["subCategoryField"]);
  };

  useEffect(() => {
    if (activeTab === "1") {
      getCategoryDetails();
    }
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      getSubCategoryDetails();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (id !== "add") {
      getBannerDetailById();
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manage-banners"
        heading={`${id === "add" ? "Add" : "Edit"} Banner`}
      />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12} sm={24}>
                <Form.Item
                  name="image"
                  rules={[
                    {
                      required: true,
                      message: "Please upload an image",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {image ? (
                      <img
                        style={{
                          height: 200,
                          width: 300,
                          // borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={image}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            {activeTab === "1" ? (
              <Row gutter={16}>
                <Col xs={24} md={12}  sm={24}>
                  <Form.Item
                    name="categoryOfTheProduct"
                    rules={[
                      {
                        required: true,
                        message: "Please select Category of the product!",
                      },
                    ]}
                  >
                    <div>
                      <h4>Select Category of the Product</h4>
                      <Select
                        allowClear
                        maxLength={20}
                        placeholder="Select Category"
                        className="select_type"
                        onChange={(val) => handleChangeForSelectedCategory(val)}
                        value={selectedCategory}
                        style={{ height: 60, width: "100%" }}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          (
                            option?.label?.toString()?.toLowerCase() || ""
                          ).indexOf(input.toLowerCase()) !== -1
                        }
                      >
                        {allCategoryData?.map((item, index) => (
                          <Option
                            key={index}
                            value={item?._id}
                            label={item?.name}
                          >
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}  sm={24}>
                  <Form.Item
                    name="subCategoryField"
                    rules={[
                      {
                        required: true,
                        message: "Please select Sub Category of the product!",
                      },
                    ]}
                  >
                    <div>
                      <h4>Select Sub Category of the Product</h4>
                      <Select
                        allowClear
                        maxLength={20}
                        placeholder="Select Category"
                        className="select_type"
                        disabled={selectedCategory ? false : true}
                        onChange={(val) =>
                          handleChangeForSelectedSubCategory(val)
                        }
                        value={selectedSubCategory}
                        style={{ height: 60, width: "100%" }}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                          (
                            option?.label?.toString()?.toLowerCase() || ""
                          ).indexOf(input.toLowerCase()) !== -1
                        }
                      >
                        {allSubCategoryData?.map((item, index) => (
                          <Option
                            key={index}
                            value={item?._id}
                            label={item?.name}
                          >
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            ) : null}

            <Row gutter={16}>
              <Col xs={24} md={12}  sm={24}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input title of banner!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Title must be at least 5 characters",
                    },

                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Please enter only alphabets",
                    },
                  ]}
                >
                  <div>
                    <h4>Title</h4>
                    <Input
                      placeholder="Title"
                      style={{ height: 55 }}
                      value={title}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>

              {activeTab === "1" ? (
                <Col xs={24} md={12}  sm={24}>
                  <Form.Item
                    name="feature"
                    rules={[
                      {
                        required: true,
                        message: "Please input feature of banner!",
                        whitespace: true,
                      },
                      {
                        min: 5,
                        message: "Feature must be at least 5 characters",
                      },

                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: "Please enter only alphabets",
                      },
                    ]}
                  >
                    <div>
                      <h4>Feature</h4>
                      <Input
                        placeholder="Feature"
                        style={{ height: 55 }}
                        value={feature}
                        onChange={(e) => {
                          setFeature(e.target.value);
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              ) : null}
            </Row>

            <Form.Item>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  // onClick={() => {
                  //   navigate("/manageUsers", { replace: true });
                  // }}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default BannerForm;
