import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import type { ColumnsType } from "antd/es/table";
import {
  usePostAddSubAdminMutation,
  usePostEditSubAdminMutation,
  useLazyGetSubAdminRoleQuery,
  useLazyGetSubAdminDetailsIdQuery,
} from "../../services/subAdmin";
import { UploadMedia } from "../../utils/mediaUpload";
import { useNavigate, useParams } from "react-router-dom";

import { RcFile } from "antd/es/upload";
import { errorToast, successToast } from "../../helpers";
import { isString } from "../../utils/validations";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";

const { Option } = Select;

interface DataType {
  _id?: string;
  label: string;
  isAdd: boolean;
  isView: boolean;
  isDelete?: boolean;
  disabled?: boolean;
}

interface RoleType {
  _id: string;
  role: string;
}

const FormData = (props: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setPhoneNo] = useState("");
  const [image, setImage] = useState<string>("");
  const [roleData, setRoleData] = useState<RoleType[]>([]);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    password: "",
  });
  const [newData, setNewData] = useState<DataType[]>([] as any);
  const [selectRole, setSelectRole] = useState<any>();

  const [error, setError] = useState(false);

  const [getSubAdminById, getSubAdminByIdData] =
    useLazyGetSubAdminDetailsIdQuery();
  const [addSubAdmin, addSubAdminData] = usePostAddSubAdminMutation();
  const [updateSubAdmin, updateSubAdminData] = usePostEditSubAdminMutation();
  const [getAdminRole, getAdminRoleData] = useLazyGetSubAdminRoleQuery();

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
  };

  const handleChange = (value: any) => {
    form.setFieldsValue({
      role: value,
    });
    setSelectRole(value);
    form.validateFields(["role"]);
  };

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  const [permissions, setPermissions] = useState<DataType[]>([
    {
      _id: "1",
      label: "Dashboard",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },

    {
      _id: "2",
      label: "Manage Users",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "3",
      label: "Listed Products Posts",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "4",
      label: "Featured Products",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "5",
      label: "Product Categories",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "6",
      label: "Event Categories",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },

    {
      _id: "7",
      label: "Donation Categories",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },

    {
      _id: "8",
      label: "Shop Categories",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },

    {
      _id: "9",
      label: "Manage Event Owner",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "10",
      label: "Manage Donation Organization",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "11",
      label: "Manage Shops",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "12",
      label: "Booking Event Listing",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "13",
      label: "Donations",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "14",
      label: "Fund Raising",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "15",
      label: "Manage Membership Plans",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "16",
      label: "Notifications",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "17",
      label: "Customer Support",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "18",
      label: "Manage CMS",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "19",
      label: "Manage FAQ's",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
  ]);

  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.label === key.label);
    if (type === "addEdit") {
      permissions[idx].isAdd = !key.isAdd;
      if (key.isAdd) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    } else if (type === "isview") {
      permissions[idx].isView = !key.isView;
    } else {
      permissions[idx].isDelete = !key.isDelete;
      if (key.isDelete) {
        permissions[idx].isView = true;
      } else {
        permissions[idx].isView = false;
      }
    }
    setPermissions([...permissions]);
  };

  const onFinish = async (values: any) => {
    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 6) {
      setError(true);
      return;
    }

    permissions?.length > 0 &&
      permissions.map((element) => {
        const obj = {
          label: element?.label,
          isAdd: element.isAdd,
          isView: element.isView,
          isDelete: element.isDelete,
        };
        if (obj) {
          newData?.push(obj);
          setNewData([...newData]);
        }
      });
    try {
      let res;
      if (props?.path) {
        let data = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          phone: phoneNo,
          image: image,

          coAdminRole: values.role,
          password: props?.path ? values.password : "",
          permissions: newData.filter((element) => {
            return (
              element.isView === true ||
              element.isAdd === true ||
              element.isDelete === true
            );
          }),
        };
        console.log(data, "data");

        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

        res = await addSubAdmin(encryptedBody).unwrap();
      } else {
        let data = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
          phone: phoneNo,
          image: image,
          password: values?.password,
          coAdminRole: values.role,
          permissions: newData.filter((element) => {
            return (
              element.isView === true ||
              element.isAdd === true ||
              element.isDelete === true
            );
          }),
        };

        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

        res = await updateSubAdmin({
          encryptedBody,
          subadmin_id: id || "",
        }).unwrap();
      }

      if (res?.statusCode === 200) {
        navigate("/managesubadmins", { replace: true });
        !id
          ? successToast("Sub Admin added successfully")
          : successToast("Sub Admin updated successfully");
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Modules",
      dataIndex: "label",
      key: "label",
      width: "55%",
    },
    {
      title: "Add/Edit",
      key: "isAdd",
      width: "15%",
      render: (record: DataType) => {
        if (
          record.label === "Notifications" ||
          record.label === "Donations" ||
          record.label === "Customer Support" ||
          record.label === "Dashboard" ||
          record.label === "Fund Raising"
        ) {
          return <span>-</span>;
        }

        return (
          <Checkbox
            onChange={() => onSelectChange(record, "addEdit")}
            checked={record.isAdd || false}
          />
        );
      },
    },
    {
      title: "View",
      key: "isView",
      width: "15%",
      render: (record: DataType) => {
        return (
          <Checkbox
            onChange={() => onSelectChange(record, "isview")}
            checked={record.isView || false}
          />
        );
      },
    },
    {
      title: "Delete",
      key: "isDelete",
      width: "15%",
      render: (record: DataType) => {
        if (
          record.label === "Manage CMS" ||
          record.label === "Notifications" ||
          record.label === "Dashboard" ||
          record.label === "Donations" ||
          record.label === "Fund Raising"
        ) {
          return <span>-</span>;
        }

        return (
          <Checkbox
            onChange={() => onSelectChange(record, "isdel")}
            checked={record.isDelete || false}
          />
        );
      },
    },
  ];

  const getSubAdminDataById = async () => {
    try {
      const res = await getSubAdminById(id).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          email: res?.data?.email,
          phone: res?.data?.phone,
          firstName: res?.data?.firstName,
          lastName: res?.data?.lastName,
          address: res?.data?.address,
          role: res?.data?.coAdminRole?._id,
        });

        setSelectRole(res?.data?.coAdminRole?._id);
        setPhoneNo(res?.data?.phone || "");
        setPhoneCode(res?.data?.countryCode || "");
        setImage(res?.data?.image || "");
        const modifiedArr2 = permissions.map((item) => {
          const foundItem = res?.data?.permissions?.find(
            (i: any) => i?.label === item.label
          );
          if (foundItem) {
            return {
              ...item,
              isView: foundItem.isView,
              isAdd: foundItem.isAdd,
              isDelete: foundItem.isDelete,
            };
          }
          return item;
        });
        setPermissions(modifiedArr2);
        setValues({
          ...values,
          firstName: res?.data?.firstName || "",
          lastName: res?.data?.lastName || "",
          email: res?.data?.email || "",
          address: res?.data?.address || "",
        });
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getRoleData = async (page: number) => {
    try {
      let body = {
        page: page,
        size: 10,
      };
      const res = await getAdminRole(body).unwrap();
      if (res?.statusCode === 200) {
        setRoleData(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoleData(1);
    if (!props?.path) {
      getSubAdminDataById();
    }
  }, []);

  return (
    <div>
      <Spin
        spinning={getSubAdminByIdData.isLoading || getAdminRoleData.isLoading}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12}>
              <h4>Image</h4>
              <Form.Item name="image">
                <Upload
                  beforeUpload={handleImageUpload}
                  showUploadList={false}
                  className="upload_imgD"
                  accept="image/*"
                >
                  <div>
                    <h4>Upload Profile Image</h4>
                    <Button icon={<UploadOutlined />}>Click to upload</Button>
                  </div>
                  {image ? (
                    <img
                      style={{
                        height: 110,
                        width: 110,
                        borderRadius: "50%",
                        marginTop: 20,
                      }}
                      src={image}
                      alt="profile"
                    />
                  ) : (
                    <></>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: "Name must be at least 3 characters",
                  },
                ]}
              >
                <div>
                  <h4>First Name</h4>
                  <Input
                    value={values.firstName}
                    onChange={(e) => {
                      if (e.target.value === " " || e.target.value === ".") {
                      } else if (isString(e.target.value)) {
                        setValues({ ...values, firstName: e.target.value });
                      }
                    }}
                    placeholder="Full Name"
                    style={{ height: 55 }}
                    maxLength={50}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={12}>
              <Form.Item name="lastName">
                <div>
                  <h4>Last Name</h4>
                  <Input
                    value={values.lastName}
                    onChange={(e) => {
                      if (e.target.value === " " || e.target.value === ".") {
                      } else if (isString(e.target.value)) {
                        setValues({ ...values, lastName: e.target.value });
                      }
                    }}
                    placeholder="Last Name"
                    style={{ height: 55 }}
                    maxLength={50}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12}>
              <Form.Item
                name="phone"
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: "Please input phone number!",
                    // },
                  ]
                }
              >
                <div>
                  <h4>Phone Number</h4>
                  <div className="phn_cs">
                    <PhoneInput
                      value={phoneCode + phoneNo}
                      enableSearch={true}
                      placeholder="Phone number"
                      containerClass={"cntn_telCls"}
                      inputClass={"cstm_cls"}
                      buttonClass={"custm_btn"}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                    />
                  </div>
                  {error && !phoneNo ? (
                    <h3 className="error_msg">
                      Please input your phone number!
                    </h3>
                  ) : null}
                  {error && phoneNo?.length && phoneNo?.length < 6 ? (
                    <h3 className="error_msg">
                      Please input valid phone number!
                    </h3>
                  ) : null}
                </div>
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={12}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  {
                    type: "email",
                    message: "The input is not valid email!",
                  },
                ]}
              >
                <div>
                  <h4>Email</h4>
                  <Input
                    value={values.email}
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                    style={{ height: 55 }}
                    placeholder="Email"
                    maxLength={30}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col sm={24} xs={24} md={12}>
              <Form.Item
                name="role"
                rules={[
                  {
                    required: true,
                    message: "Please select a role",
                  },
                ]}
              >
                <div>
                  <h4>Role</h4>
                  <Select
                    // defaultValue={selectRole?.name}
                    value={selectRole}
                    style={{ width: "100%" }}
                    onChange={(val) => handleChange(val)}
                    placeholder={"Choose Role"}
                  >
                    {roleData?.map((item, index) => {
                      return (
                        <Option key={index} value={item?._id}>
                          {item?.role}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Form.Item>
            </Col>
            {props?.path ? (
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input password!",
                    },
                    {
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Must contain 8 or more characters, one uppercase, one lowercase, one special character and one number.",
                    },
                  ]}
                >
                  <div>
                    <h4>Password</h4>
                    <Input.Password
                      // disabled
                      value={values.password}
                      onChange={(e) =>
                        setValues({ ...values, password: e.target.value })
                      }
                      style={{ height: 55 }}
                      placeholder="Password"
                    />
                  </div>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
          </Row>

          <Row gutter={16}>
            <Col xs={24} md={24}>
              <Table
                style={{ marginTop: 20, marginBottom: 20 }}
                pagination={false}
                dataSource={permissions}
                columns={columns}
                rowKey={"_id"}
              />
            </Col>
          </Row>

          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div
              style={{ display: "flex", marginTop: 20, alignItems: "center" }}
            >
              <Button
                onClick={() => {
                  setError(true);
                }}
                loading={
                  addSubAdminData.isLoading || updateSubAdminData.isLoading
                }
                style={{
                  background: "#ECAC35",
                  width: 200,
                  height: 50,
                  margin: "auto",
                  color: "black",
                }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default FormData;
