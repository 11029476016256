import { END_POINTS } from "../helpers";
import { CommonBody, category } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CategoryResponse = {
  statusCode: number;
  message: string;
};

type PutUpdateCategoryParams = {
  categoryId: string | undefined;
  encryptedBody: CommonBody;
};

type CommonResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          category: category[] | [];
        };
      },
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.category +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,
        method: "GET",
      }),
    }),

    addCategory: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          produCategory: category[] | [];
        };
      },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.category,
        method: "POST",
        body,
      }),
    }),

    deleteLoanType: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          productCategory: category[] | [];
        };
      },
      { categoryId: string }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.category}/${categoryId}`,
        method: "DELETE",
      }),
    }),

    getCategoryById: builder.query<
      CategoryResponse & {
        data: any;
      },
      string | undefined
    >({
      query: (categoryId) => ({
        url: `${END_POINTS.category}/${categoryId}`,
        method: "GET",
      }),
    }),

    putUpdateCategory: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          productCategory: category[] | [];
        };
      },
      PutUpdateCategoryParams
    >({
      query: ({ encryptedBody, categoryId }) => ({
        url: `${END_POINTS.category}/${categoryId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    getEventCategory: builder.query<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          eventCategory: category[] | [];
        };
      },
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.eventCategory +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,
        method: "GET",
      }),
    }),

    postAddEventCategory: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          productCategory: category[] | [];
        };
      },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.eventCategory,
        method: "POST",
        body,
      }),
    }),

    deleteEventCategory: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          productCategory: category[] | [];
        };
      },
      { categoryId: string }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.eventCategory}/${categoryId}`,
        method: "DELETE",
      }),
    }),

    putUpdateEventCategory: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          productCategory: category[] | [];
        };
      },
      PutUpdateCategoryParams
    >({
      query: ({ encryptedBody, categoryId }) => ({
        url: `${END_POINTS.eventCategory}/${categoryId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),

    getEventCategoryById: builder.query<
      CategoryResponse & {
        data: any;
      },
      string | undefined
    >({
      query: (categoryId) => ({
        url: `${END_POINTS.eventCategory}/${categoryId}`,
        method: "GET",
      }),
    }),

    getDonationCategory: builder.query<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          donationCategory: category[] | [];
        };
      },
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.donationCategory +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,
        method: "GET",
      }),
    }),

    postAddDonationCategory: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          donationCategory: category[] | [];
        };
      },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.donationCategory,
        method: "POST",
        body,
      }),
    }),

    deleteDonationCategory: builder.mutation<
      CategoryResponse,
      { categoryId: string }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.donationCategory}/${categoryId}`,
        method: "DELETE",
      }),
    }),

    getDonationCategoryById: builder.query<
      CategoryResponse & {
        data: any;
      },
      string | undefined
    >({
      query: (categoryId) => ({
        url: `${END_POINTS.donationCategory}/${categoryId}`,
        method: "GET",
      }),
    }),

    putUpdateDonationCategory: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          donationCategory: category[] | [];
        };
      },
      PutUpdateCategoryParams
    >({
      query: ({ encryptedBody, categoryId }) => ({
        url: `${END_POINTS.donationCategory}/${categoryId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    getShopCategoryDetails: builder.query<
      CommonResponse,
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.shopCategories +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,
        method: "GET",
      }),
    }),
    getShopCategoryById: builder.query<
      CategoryResponse & {
        data: any;
      },
      string | undefined
    >({
      query: (categoryId) => ({
        url: `${END_POINTS.shopCategories}/${categoryId}`,
        method: "GET",
      }),
    }),
    addShopCategory: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          shopCategory: category[] | [];
        };
      },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.shopCategories,
        method: "POST",
        body,
      }),
    }),
    putUpdateShopCategory: builder.mutation<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          shopCategory: category[] | [];
        };
      },
      PutUpdateCategoryParams
    >({
      query: ({ encryptedBody, categoryId }) => ({
        url: `${END_POINTS.shopCategories}/${categoryId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    deleteShopCategory: builder.mutation<
      CategoryResponse,
      { categoryId: string }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.shopCategories}/${categoryId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetCategoryQuery,
  useAddCategoryMutation,
  useDeleteLoanTypeMutation,
  useLazyGetCategoryByIdQuery,
  usePutUpdateCategoryMutation,
  useLazyGetEventCategoryQuery,
  usePostAddEventCategoryMutation,
  useDeleteEventCategoryMutation,
  usePutUpdateEventCategoryMutation,
  useLazyGetEventCategoryByIdQuery,
  useLazyGetDonationCategoryQuery,
  usePostAddDonationCategoryMutation,
  useDeleteDonationCategoryMutation,
  useLazyGetDonationCategoryByIdQuery,
  usePutUpdateDonationCategoryMutation,
  useLazyGetShopCategoryDetailsQuery,
  useLazyGetShopCategoryByIdQuery,
  useAddShopCategoryMutation,
  usePutUpdateShopCategoryMutation,
  useDeleteShopCategoryMutation,
} = categoryApi;
