import { Button, Card, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetOrganizationDetailsIdQuery } from "../../../services/donation";

const OrganizationDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [getOrganizationDetailsById] = useLazyGetOrganizationDetailsIdQuery();

  const [loading, setLoading] = useState(false);
  const [organizationDetail, setOrganizationDetail] = useState<any>();

  const getOwnerDetailsById = async () => {
    try {
      setLoading(true);
      const res = await getOrganizationDetailsById(id).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setOrganizationDetail(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getOwnerDetailsById();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Organization details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/manage-organization", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <h4>Organization image</h4>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      height: 100,
                      borderRadius: 50,
                    }}
                    src={
                      organizationDetail?.image || "/static/images/dummy.png"
                    }
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Organization Name</h4>
                <h5>{organizationDetail?.name || "-"}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Email</h4>
                <h5>{organizationDetail?.email || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Phone Number</h4>
                <h5>
                  {`${organizationDetail?.countryCode || ""} ${
                    organizationDetail?.phone || "-"
                  }`}
                </h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Request Status</h4>
                <h5>{organizationDetail?.docsApproved}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Document Front Image</h4>
                {organizationDetail?.docFrontImage ? (
                  <img
                    style={{
                      height: 200,
                      width: 200,
                      borderRadius: 10,
                    }}
                    src={organizationDetail?.docFrontImage}
                    alt=""
                  />
                ) : (
                  <h5>Not found</h5>
                )}
              </Col>
              <Col xs={20} md={8}>
                <h4>Document Back Image</h4>
                {organizationDetail?.docBackImage ? (
                  <img
                    style={{
                      height: 200,
                      width: 200,
                      borderRadius: 10,
                    }}
                    src={organizationDetail?.docBackImage}
                    alt=""
                  />
                ) : (
                  <h5>Not found</h5>
                )}
              </Col>
            </Row>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default OrganizationDetails;
