import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Row } from "antd";
import React, { Dispatch, SetStateAction } from "react";

type ListType = {
  type: any;
  name: string;
  checked: boolean;
  disabled?: boolean | undefined;
};

const staticFields = [
  {
    name: "Images",
    checked: true,
    type: "string",
    disabled: true,
  },
  {
    name: "Name",
    checked: true,
    type: "string",
    disabled: true,
  },
  {
    name: "Description",
    checked: true,
    type: "string",
    disabled: true,
  },
  {
    name: "Price",
    checked: true,
    type: "string",
    disabled: true,
  },
  {
    name: "Address",
    checked: true,
    type: "string",
    disabled: true,
  },
];

type ManageProps = {
  handleItemCheckboxChange: (args: any) => void;
  itemList: ListType[];
  Form: any;
  brandName: string;
  setBrandName: Dispatch<SetStateAction<string>>;
  addBrandName: () => void;
  brandsData: {
    name: string;
  }[];
  removeBrandName: (args: any) => void;
  toggle: boolean;
  addNewFieldClick: () => void;
  handleAddField: () => void;
  inputRef: any;
  selectRef: any;
  handleRemoveItem: (args: any, item: any) => void;
};

const DynamicInput = ({
  handleItemCheckboxChange,
  itemList,
  Form,
  brandName,
  setBrandName,
  addBrandName,
  brandsData,
  removeBrandName,
  toggle,
  addNewFieldClick,
  handleAddField,
  inputRef,
  selectRef,
  handleRemoveItem,
}: ManageProps) => {
  return (
    <div>
      <Row gutter={16}>
        <Col xs={24} md={12} sm={24}>
          <div>
            <h4>Select Fields</h4>
            {staticFields.map((item, index) => (
              <div key={index}>
                <div className="select_div">
                  <span>{item.name}</span>
                  <Checkbox
                    checked={item.checked}
                    onChange={() => handleItemCheckboxChange(index)}
                    disabled
                  />
                </div>
              </div>
            ))}
            {itemList.map((item, index) => (
              <div key={index}>
                <div className="select_div">
                  <span>{item.name}</span>
                  <div>
                    <Checkbox
                      checked={item.checked}
                      onChange={() => handleItemCheckboxChange(index)}
                    />

                    {item?.name === "Brand" ? undefined : (
                      <CloseCircleOutlined
                        onClick={() => handleRemoveItem(index, item)}
                        className="handle_cross_clk"
                      />
                    )}
                  </div>
                </div>
                {item?.name === "Brand" && item?.checked ? (
                  <>
                    <Row gutter={16} align="bottom" className="row2">
                      <Form.Item name="brandName" style={{ width: "90%" }}>
                        <Input
                          placeholder="Brand Name"
                          type="text"
                          name="brandName"
                          value={brandName}
                          className="brandInput"
                          onChange={(val) => {
                            setBrandName(val.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <PlusCircleOutlined
                          style={{
                            fontSize: "25px",
                            cursor: brandName ? "pointer" : "not-allowed",
                            color: brandName ? "#FEA500" : "#d9d9d9",
                          }}
                          disabled={brandName ? false : true}
                          onClick={addBrandName}
                        />
                      </Form.Item>
                    </Row>
                    <div className="brand-listing">
                      {brandsData?.map((item, index) => (
                        <Row key={index} gutter={16} align="bottom">
                          <Form.Item name="brandName">
                            <div
                              className="flex_dix"
                              style={{ display: "flex" }}
                            >
                              <h3>{item?.name}</h3>
                              <CloseCircleOutlined
                                onClick={() => removeBrandName(index)}
                              />
                            </div>
                          </Form.Item>
                        </Row>
                      ))}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12} sm={24}>
          <div className="input_type">
            <form>
              {!toggle ? (
                <div className="center">
                  <Button
                    className="click_here"
                    // @ts-ignore
                    type="secondary"
                    onClick={addNewFieldClick}
                    // @ts-ignore
                    htmlType="add"
                  >
                    Click here to add new field
                  </Button>
                </div>
              ) : (
                <div className="dialog-box-lable">
                  <input
                    type="text"
                    placeholder="label"
                    ref={inputRef}
                    className="lable-input"
                  />
                  <select ref={selectRef}>
                    <option value="string">Text</option>
                    <option value="number">Number</option>
                  </select>

                  <Button
                    style={{
                      backgroundColor: "white",
                    }}
                    // @ts-ignore
                    type="secondary"
                    onClick={handleAddField}
                    // @ts-ignore
                    htmlType="add"
                  >
                    Add
                  </Button>
                </div>
              )}
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DynamicInput;
