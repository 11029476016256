import { END_POINTS } from "../helpers";
import { CommonBody, shop } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  data?: {
    count: number;
    pages: number;
    shop: shop[] | [];
  };
  message: string;
};

type PutUpdateShopParams = {
  shopId: string | undefined;
  encryptedBody: CommonBody;
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getShops: builder.query<CommonResponse, { page: number; search: string }>({
      query: ({ page, search }) => ({
        url:
          END_POINTS.shops + "?page=" + page + "&size=10" + "&search=" + search,
        method: "GET",
      }),
    }),

    getShopDetailsId: builder.query<
      CommonResponse & { data: shop },
      string | undefined
    >({
      query: (shopId) => ({
        url: `${END_POINTS.shops}/${shopId}`,
        method: "GET",
      }),
    }),

    deleteShop: builder.mutation<CommonResponse, { shopId: string }>({
      query: ({ shopId }) => ({
        url: `${END_POINTS.shops}${shopId}`,
        method: "DELETE",
      }),
    }),

    putUpdateShops: builder.mutation<CommonResponse, PutUpdateShopParams>({
      query: ({ encryptedBody, shopId }) => ({
        url: `${END_POINTS.shops}/${shopId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
  }),
});

export const {
  useLazyGetShopsQuery,
  useLazyGetShopDetailsIdQuery,
  useDeleteShopMutation,
  usePutUpdateShopsMutation,
} = categoryApi;
