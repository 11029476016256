import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader } from "../../components";
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CommonBody, MembershipPlan } from "../../types/General";
import {
  useDeletePlanMutation,
  useLazyGetPlanDetailsQuery,
  usePutBlockMembershipMutation,
} from "../../services/memberships";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useAuth from "../../hooks/useAuth";

const { confirm } = Modal;

const MembershipPlans = () => {
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [planList, setPlanList] = useState<MembershipPlan[]>([]);
  const [planListQuery, planListDataQuery] = useLazyGetPlanDetailsQuery();
  const [deletePlan, deletePlanMutation] = useDeletePlanMutation();
  const [blockPlan, blockPlanMutation] = usePutBlockMembershipMutation();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getPlanDetails(page);
  };

  const navigate = useNavigate();

  const handleBlock = async (isBlocked: boolean, _id: string) => {
    let data = {
      isBlocked: isBlocked,
    };
    console.log(isBlocked, _id, "both");
    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const res = await blockPlan({ encryptedBody, planId: _id }).unwrap();
      if (res?.statusCode === 200) {
        getPlanDetails(currentPage);
        successToast(
          isBlocked
            ? "Plan Deactivated Successfully!"
            : "Plan Activated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deletePlan({ planId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("User Deleted Successfully");
        getPlanDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this plan?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getPlanDetails = async (page: number) => {
    try {
      const res = await planListQuery({
        page: page,
        size: 10,
        search: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setPlanList(res?.data?.membership);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [debouncedQuery]);

  useEffect(() => {
    getPlanDetails(1);
  }, [query]);

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Manage Membership Plans"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };
  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const columns: ColumnsType<MembershipPlan> = [
    {
      title: "Plan Name",
      dataIndex: "planName",
      key: "planName",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (text, record) => {
        if (record?.type === "FREE") {
          return "---";
        }
        return <span>{text}</span>;
      },
    },

    {
      title: "Validity",
      dataIndex: "validity",
      key: "validity",
      align: "center",
      render: (text, record) => {
        if (record.type === "FREE") {
          return "---";
        }
        return <span>{text}</span>;
      },
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-membership-plans/details/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/manage-membership-plans/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : (
            ""
          )}

          {hidePermission?.isDelete ? (
            <Button
              disabled={record?.type === "FREE" ? true : false}
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Plan Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Switch
          disabled={record?.type === "FREE" ? true : false}
          size="small"
          defaultChecked={!record?.isBlocked}
          onChange={() => handleBlock(!record?.isBlocked, record?._id)}
        />
      ),
    },
  ];

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Manage Membership plans"
        buttonNavigateTo="manage-membership-plans/add"
        buttonText={`${hidePermission?.isAdd ? "Add Plan" : ""}`}
      />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
              />
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={planList}
            rowKey="_id"
            pagination={pagination}
            loading={
              planListDataQuery.isLoading ||
              blockPlanMutation.isLoading ||
              deletePlanMutation.isLoading
            }
          />
        </Card>
      </div>
    </div>
  );
};

export default MembershipPlans;
