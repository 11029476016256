import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader } from "../../components";
import { DonationBooking } from "../../types/General";
import {
  EyeOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  useLazyGetDonationBookingQuery,
  useLazyGetDonationBookingCsvQuery,
} from "../../services/donation";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { errorToast } from "../../helpers";

const Donations = () => {
  const navigate = useNavigate();
  const [getDonationBooking] = useLazyGetDonationBookingQuery();
  const [donationBookingCsv] = useLazyGetDonationBookingCsvQuery();

  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>(0);
  const [loading, setLoading] = useState(false);
  const [donationDetails, setDonationDetails] = useState<DonationBooking[]>([]);

  const columns: ColumnsType<DonationBooking> = [
    {
      title: "Donar Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign",
      key: "campaign",
      align: "center",
      render: (_, { donationId }: any) => (
        <div>
          <span>{donationId?.name}</span>
        </div>
      ),
    },
    {
      title: "Donated Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (_, { createdAt }) => (
        <div>
          <span>{moment(createdAt).format("MMM Do YYYY")}</span>
        </div>
      ),
    },

    {
      title: "Donated Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (_, { amount }) => (
        <div>
          <span>{amount ? "$" + amount : ""}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/donations/details/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const getDonationDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getDonationBooking({
        page: page,
        size: 10,
        search: query || "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setDonationDetails(res?.data?.booking);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await donationBookingCsv({}).unwrap();
      if (res?.statusCode === 200) {
        if (res?.data) {
          window.open(res?.data);
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getDonationDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    getDonationDetails(1);
  }, [query]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Donations" />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getDonationDetails(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  value={debouncedQuery}
                  onChange={(e) => setDebouncedQuery(e.target.value)}
                  maxLength={20}
                />
              </Form>
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleExportCsv}
                icon={<DownloadOutlined />}
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
              >
                Export CSV
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={donationDetails}
            pagination={pagination}
            loading={loading}
          />
        </Card>
      </div>
    </div>
  );
};

export default Donations;
