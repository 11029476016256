import { useState } from "react";
import { Card, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";
import BattingReport from "./BattingReport";
import UsersReport from "./UsersReport";

const Reports = () => {
  const [activeTab, setActiveTab] = useState("1");

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Betting Report`,
      children: <BattingReport />,
    },
    {
      key: "2",
      label: `Users Report`,
      children: <UsersReport />,
    },
  ];
  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Reports" />
      <Card>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </Card>
    </div>
  );
};

export default Reports;
