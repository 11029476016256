import { Button, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MembershipPlan } from "../../../types/General";
import { useLazyGetPlanDetailsIdQuery } from "../../../services/memberships";

const MembershipDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState<MembershipPlan>();
  const [getPlanDetailsById] = useLazyGetPlanDetailsIdQuery();

  const planDetailsById = async () => {
    try {
      setLoading(true);
      const res = await getPlanDetailsById(id).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setPlanDetails(res?.data);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    planDetailsById();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card loading={loading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Membership details</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage-membership-plans", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Plan Name</h4>
              <h5>{planDetails?.planName || "--"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Price</h4>
              <h5>{planDetails?.price || "--"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Validity</h4>
              <h5>{planDetails?.validity || "--"}</h5>
            </Col>
          </Row>

          <Row>
            <Col xs={15} md={9}>
              <h4>Description</h4>
              <h5>{planDetails?.description || "--"}</h5>
            </Col>
            <Col xs={15} md={9}>
              <h4>Feature</h4>
              <div className="feature_dv">
                <div className="feature_lst">
                  <p>Total Product Posts {planDetails?.noOfPostsAllowed? `(${planDetails?.noOfPostsAllowed})`:""}</p>
                  {planDetails?.feature === 3 || planDetails?.feature === 1 ? (
                    <figure className="feature_figure">
                      <img src="/static/images/check-mark.png" alt =""/>
                    </figure>
                  ) : (
                    <figure className="feature_figure">
                      <img src="/static/images/close.png" alt=""/>
                    </figure>
                  )}
                </div>
                <div className="feature_lst">
                  <p>Create Shop</p>
                  {planDetails?.feature === 3 || planDetails?.feature === 2 ? (
                    <figure className="feature_figure">
                      <img src="/static/images/check-mark.png" alt=""/>
                    </figure>
                  ) : (
                    <figure className="feature_figure">
                      <img src="/static/images/close.png"alt="" />
                    </figure>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default MembershipDetails;
