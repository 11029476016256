import { Card, Col, Row, Select } from "antd";
import { ContentHeader } from "../../components";
import { LineChart } from "../../components/charts";

const Analytics = () => {
  const labels = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ];

  const graphData = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: [12, 43, 54, 65, 23, 12, 17, 56, 23, 78, 54, 67, 23, 45, 89],
        borderColor: "#ECAC35",
        backgroundColor: "#C69320",
      },
    ],
  };

  const graphCustomerData = {
    labels,
    datasets: [
      {
        label: "Users",
        data: [17, 56, 23, 78, 54, 67, 23, 45, 89, 12, 43, 54, 65, 23, 12],
        borderColor: "#ECAC35",
        backgroundColor: "#C69320",
      },
    ],
  };
  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Analytics" />
      <Row gutter={16}>
        <Col sm={24} xs={24} md={12}>
          <Card className="chart_card">
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <h2>Revenue Generated</h2>
              <div style={{ width: "20%" }}>
                <Select
                  style={{ width: "100%" }}
                  // onChange={(val) => setType(val)}
                  placeholder="Filter"
                  options={[
                    { value: "daily", label: "Daily" },
                    { value: "weekly", label: "Weekly" },
                    { value: "monthly", label: "Monthly" },
                    { value: "yearly", label: "Yearly" },
                  ]}
                />
              </div>
            </div>
            <div style={{ margin: "auto" }}>
              <LineChart data={graphData} />
            </div>
          </Card>
        </Col>
        <Col sm={24} xs={24} md={12}>
          <Card className="chart_card">
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <h2>Total Users</h2>
              <div style={{ width: "20%" }}>
                <Select
                  style={{ width: "100%" }}
                  // onChange={(val) => setType(val)}
                  placeholder="Filter"
                  options={[
                    { value: "daily", label: "Daily" },
                    { value: "weekly", label: "Weekly" },
                    { value: "monthly", label: "Monthly" },
                    { value: "yearly", label: "Yearly" },
                  ]}
                />
              </div>
            </div>
            <div style={{ margin: "auto" }}>
              <LineChart data={graphCustomerData} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Analytics;
