import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";

import { DeleteOutlined, SearchOutlined, EditFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, category } from "../../types/General";
import {
  useDeleteShopCategoryMutation,
  useLazyGetShopCategoryDetailsQuery,
  usePutUpdateShopCategoryMutation,
} from "../../services/category";
import useAuth from "../../hooks/useAuth";

const { confirm } = Modal;

type PropTypes = {
  scrollPosition: ScrollPosition;
};

const ShopCategory = ({ scrollPosition }: PropTypes) => {
  const navigate = useNavigate();

  const [allShopCategory, setAllShopCategory] = useState<category[]>();
  const [shopCategoryList] = useLazyGetShopCategoryDetailsQuery();
  const [deleteShopCategory] = useDeleteShopCategoryMutation();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>(0);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [updateShopCategory] = usePutUpdateShopCategoryMutation();

  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  const getShopCategoryDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await shopCategoryList({
        page: page,
        size: 10,
        search: query || "",
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setAllShopCategory(res?.data?.shopCategory || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteShopCategory({ categoryId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Category deleted successfully");
        getShopCategoryDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const activeInactiveCategory = async (isBlocked: boolean, _id: string) => {
    const data = {
      isBlocked: isBlocked,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await updateShopCategory({
        encryptedBody,
        categoryId: _id,
      }).unwrap();
      if (res?.statusCode === 200) {
        successToast(
          isBlocked
            ? "Category Deactivated Successfully!"
            : "Category Activated Successfully!"
        );
        getShopCategoryDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Shop Categories"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const columns: ColumnsType<category> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (_, { image }) => (
        <div>
          {image ? (
            <LazyLoadImage
              alt="img"
              style={{ width: 40, height: 40, borderRadius: 5 }}
              src={image}
              effect="blur"
              scrollPosition={scrollPosition}
            />
          ) : (
            "N/A"
          )}
        </div>
      ),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/shop-categories/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : (
            ""
          )}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "deviation",
      align: "center",
      render: (_, { isBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => activeInactiveCategory(!isBlocked, _id)}
        />
      ),
    },
  ];

  useEffect(() => {
    getShopCategoryDetails(1);
  }, [query]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getShopCategoryDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [debouncedQuery]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Shop Categories" />
      <div className="srch">
        <Card>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Form style={{ display: "flex", gap: 20 }}>
                <Input
                  placeholder="Search Category"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  value={debouncedQuery}
                  onChange={(e) => setDebouncedQuery(e.target.value)}
                  maxLength={20}
                />
              </Form>
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {hidePermission?.isAdd ? (
                <Button
                  style={{
                    background: "#ECAC35",
                    height: 35,
                    color: "black",
                  }}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/shop-categories/add`);
                  }}
                >
                  Add Category
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={allShopCategory}
            loading={loading}
            rowKey="_id"
            pagination={pagination}
          />
        </Card>
      </div>
    </div>
  );
};

export default trackWindowScroll(ShopCategory);
