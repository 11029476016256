import { useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { ContentHeader } from "../../components";

import { successToast } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { usePostAddNotificationMutation } from "../../services/notifications";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";

const Notifications = () => {
  const [title, setTitle] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");

  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { Option } = Select;
  const [form] = Form.useForm();

  const [addNotification, addNotificationData] =
    usePostAddNotificationMutation();
  const { TextArea } = Input;

  const notificationTypes = [
    { name: "All", id: "All" },
    { name: "User", id: "User" },
    { name: "Owner", id: "SubAdmin" },
    { name: "Organizations", id: "DonationAdmin" },
  ];

  const onFinish = async () => {
    let data = {
      type: selectedCategory,
      notificationType: "Broadcast",
      title: title,
      message: message,
    };
    console.log(data, "datttttt");

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await addNotification(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        successToast("Notification sent successfully");

        setTitle("");
        setMessage("");
        navigate("history");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      notificationCategory: value,
    });
    setSelectedCategory(value);

    form.validateFields(["notificationCategory"]);
  };

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader
        heading="Notifications"
        buttonText="View History"
        buttonNavigateTo="notifications/history"
      />
      <Card>
        <Form
          name="basic"
          style={{
            maxWidth: "100%",
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12}>
              <h4>Title</h4>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please input Title!",
                    whitespace: true,
                  },
                ]}
              >
                <div>
                  <Input
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ height: 55 }}
                    maxLength={50}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col sm={24} xs={24} md={12}>
              <h4>Select target</h4>

              <Form.Item
                name="notificationCategory"
                rules={[
                  {
                    required: true,
                    message: "Please select a target!",
                  },
                ]}
              >
                <Select
                  allowClear
                  maxLength={20}
                  className="selector_type"
                  onChange={(val) => handleChangeForSelectedCategory(val)}
                  value={selectedCategory}
                  style={{ height: 75, width: "100%" }}
                  placeholder="Target"
                >
                  {notificationTypes?.map((item, index) => (
                    <Option key={index} value={item?.id} label={item?.name}>
                      {item?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12}>
              <h4>Description</h4>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input Description!",
                    whitespace: true,
                  },
                ]}
              >
                <div>
                  <TextArea
                    rows={8}
                    placeholder="Description"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item wrapperCol={{}}>
            <Button
              loading={addNotificationData?.isLoading}
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                marginTop: 20,
                color: "black",
              }}
              type="primary"
              htmlType="submit"
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Notifications;
