import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";
import { SubAdmins } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PutBlockSubAdminParams = {
  bannerId: string;
  encryptedBody?: CommonBody;
  activeTab: number;
};
type SubAdminRoleParams = {
  subadmin_id: string;
  encryptedBody: CommonBody;
};

type PostAddSubAdminResponse = {
  statusCode: number;
  data?: SubAdmins;
  message: string;
};

type BannerResponse = {
  _id: string;
  eventBookingBanner?: {
    image: string;
    title: string;
  }[];
  donationBanner: {
    image: string;
    title: string;
  }[];
  productBanner?: {
    feature: string;
    image: string;
    price: number;
    title: string;
  }[];
};

export const bannerApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBanners: builder.query<
      CommonResponse & { data: BannerResponse },
      { page: number; activeTab: number; search: string }
    >({
      query: ({ page, activeTab, search }) => ({
        url:
          END_POINTS.banner +
          "?bannerType=" +
          activeTab +
          "&page=" +
          page +
          "&size=10" +
          "&search=" +
          search,

        method: "GET",
      }),
    }),
    addBanner: builder.mutation<CommonResponse, CommonBody>({
      query: (encryptedBody) => ({
        url: `${END_POINTS.addBanner}`,
        method: "POST",
        body: encryptedBody,
      }),
    }),

    deleteBanner: builder.mutation<
      { statusCode: number; message: string },
      { bannerId: string; activeTab: number }
    >({
      query: ({ bannerId, activeTab }) => ({
        url: `${END_POINTS.deleteBanner}/${bannerId}?bannerType=${activeTab}`,
        method: "DELETE",
      }),
    }),

    updateBanner: builder.mutation<
      { statusCode: number },
      PutBlockSubAdminParams
    >({
      query: ({ encryptedBody, bannerId, activeTab }) => ({
        url: `${END_POINTS.updateBanner}/${bannerId}?bannerType=${activeTab}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    updateBannerStatus: builder.mutation<
      { statusCode: number; message: string },
      { body: CommonBody; bannerId: string; activeTab: number }
    >({
      query: ({ body, bannerId, activeTab }) => ({
        url: `${END_POINTS.updateBanner}/${bannerId}?bannerType=${activeTab}`,
        method: "PUT",
        body,
      }),
    }),
    getBannerById: builder.query<
      { statusCode: number; message: string; data: any },
      PutBlockSubAdminParams
    >({
      query: ({ bannerId, activeTab }) => ({
        url: `${END_POINTS.banner}/?bannerType=${activeTab}&bannerId=${bannerId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetBannersQuery,
  useAddBannerMutation,
  useUpdateBannerMutation,
  useDeleteBannerMutation,
  useLazyGetBannerByIdQuery,
  useUpdateBannerStatusMutation,
} = bannerApi;
