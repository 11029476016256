import { Button, Card, Col, Form, Input, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import EditText from "../../components/textEditor";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLazyGetCmsQuery, useUpdateCmsMutation } from "../../services/auth";
import { errorToast, successToast } from "../../helpers";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useAuth from "../../hooks/useAuth";

const ManageCms = () => {
  const userData = useAuth();

  const [cmsDetails, cmsDetailsData] = useLazyGetCmsQuery();
  const [updateCms, updateCmsData] = useUpdateCmsMutation();
  const [email, setEmail] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [terms, setTerms] = useState("");
  const [type, setType] = useState("PERCENTAGE");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [phoneNo, setPhoneNo] = useState("");
  const [commission, setCommission] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [error, setError] = useState(false);

  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
  };

  const onFinish = async () => {
    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 6) {
      setError(true);
      return;
    }
    if (type === "PERCENTAGE" && commission > 100) {
      errorToast("In % , commission can't be greater then 100");
    }
    if (tax > 100) {
      errorToast("In % , tax can't be greater then 100");
    } else {
      let data = {
        email: email,
        phone: phoneNo,
        privacyPolicy: privacyPolicy || "",
        termsAndCondition: terms,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        eventCountryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        donationCountryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        commission,
        commissionType: type,
        eventEmail: email,
        eventPhone: phoneNo,
        donationEmail: email,
        donationPhone: phoneNo,
        tax,
      };

      console.log(data, "data");

      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      try {
        const res = await updateCms(encryptedBody).unwrap();

        if (res?.statusCode === 200) {
          successToast("CMS Updated Successfully");
          getCmsDetail();
        }
      } catch (error: any) {
        console.log(error, "error");
        errorToast(error?.message);
      }
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Event Categories"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const getCmsDetail = async () => {
    try {
      const res = await cmsDetails({}).unwrap();

      if (res?.statusCode === 200) {
        setPrivacyPolicy(res?.data?.privacyPolicy || "");
        setTerms(res?.data?.termsAndCondition || " ");
        setEmail(res?.data?.email);
        setPhoneNo(res?.data?.phone);
        setPhoneCode(res?.data?.countryCode);
        setType(res?.data?.commissionType);
        setCommission(res?.data?.commission);
        setTax(res?.data?.tax);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <div style={{ padding: 40 }}>
      <ContentHeader heading="Manage CMS" />
      <div style={{ marginBottom: 18 }}>
        <span style={{ fontSize: 17, fontWeight: 600 }}>
          Content Management System
        </span>
      </div>

      <Card>
        <Spin spinning={cmsDetailsData.isLoading}>
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12}>
              <h3>Privacy Policy</h3>
              {privacyPolicy ? (
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
              ) : null}
            </Col>
            <Col sm={24} xs={24} md={12}>
              <h3>Legal</h3>
              {terms ? (
                <EditText content={terms} setContent={setTerms} />
              ) : null}
            </Col>
          </Row>

          <Form
            onFinish={onFinish}
            name="basic"
            style={{
              maxWidth: "100%",
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Row gutter={16} style={{ marginTop: 30 }}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item name="commissionType" rules={[]}>
                  <h4>Commission Type</h4>
                  <Select
                    value={type}
                    className="selector_type"
                    style={{ height: 75, width: "100%" }}
                    onChange={(val) => setType(val)}
                    placeholder="Type"
                    options={[
                      { value: "PERCENTAGE", label: "Percentage" },
                      { value: "FLAT", label: "Flat" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="commission"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter only numeric values",
                    },
                  ]}
                >
                  <h4>Commission</h4>
                  <Input
                    value={commission}
                    prefix={type === "FLAT" ? <p>$</p> : null}
                    suffix={type === "PERCENTAGE" ? <p>%</p> : null}
                    placeholder="Commission"
                    style={{ height: 55 }}
                    onChange={(e) => {
                      const input = Number(e.target.value);
                      if (!isNaN(input)) {
                        setCommission(input);
                      }
                    }}
                    maxLength={20}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="tax"
                  rules={[
                    {
                      pattern: /^[0-9]*$/,
                      message: "Please enter only numeric values",
                    },
                  ]}
                >
                  <h4>Tax</h4>
                  <Input
                    value={tax}
                    suffix={<p>%</p>}
                    placeholder="Tax"
                    style={{ height: 55 }}
                    onChange={(e) => {
                      const input = Number(e.target.value);
                      if (!isNaN(input)) {
                        setTax(input);
                      }
                    }}
                    maxLength={3}
                  />
                </Form.Item>
              </Col>
            </Row>

            <h3>Contact Us</h3>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "Enter a valid email address!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input contact details",
                    },
                  ]}
                >
                  <h4>Phone Number</h4>
                  <div className="phn_cs">
                    <PhoneInput
                      value={phoneCode + phoneNo}
                      enableSearch={true}
                      placeholder="Phone number"
                      containerClass={"cntn_telCls"}
                      inputClass={"cstm_cls"}
                      buttonClass={"custm_btn"}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                    />
                  </div>
                  {error && !phoneNo ? (
                    <h3 className="error_msg">
                      Please input your phone number!
                    </h3>
                  ) : null}
                  {error && phoneNo?.length && phoneNo?.length < 6 ? (
                    <h3 className="error_msg">
                      Please input valid phone number!
                    </h3>
                  ) : null}
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row gutter={4}>
            <Col xs={24} md={24}>
              {hidePermission?.isAdd ? (
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    marginTop: 20,
                    color: "black",
                  }}
                  type="primary"
                  onClick={onFinish}
                  loading={updateCmsData.isLoading}
                >
                  Save
                </Button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Spin>
      </Card>
    </div>
  );
};

export default ManageCms;
