import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";
import type { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: User;
  message: string;
};

type PostForgotPasswordResponse = {
  statusCode: number;
  data?: User;
  message: string;
};

type PostVerifyOtpResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PostResetPasswordBody = {
  password?: string;
};

type PostResetPasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PostChangePasswordBody = {
  oldPassword?: string;
  password?: string;
};

type PostChangePasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type GetProfileResponse = {
  statusCode: number;
  code?: number;
  data?: any;
};

type PutUpdateProfileBody = {
  email: string;
  name: string;
  countryCode: string;
  phone: string;
  address: string;
  image?: string;
};

type PutUpdateProfileResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type CommonResponse = {
  statusCode: number;
  message?: string;
  data?: any;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    postSignUp: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.signUp,
        method: "POST",
        body,
      }),
    }),

    postForgotPassword: builder.mutation<
      PostForgotPasswordResponse,
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),

    postVerifyOtp: builder.mutation<PostVerifyOtpResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.verifyOtp,
        method: "POST",
        body,
      }),
    }),
    putUpdateProfile: builder.mutation<PutUpdateProfileResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.updateProfile,
        method: "PUT",
        body,
      }),
    }),
    postResetPassword: builder.mutation<PostResetPasswordResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),
    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),
    getProfile: builder.query<GetProfileResponse, {}>({
      query: () => ({
        url: END_POINTS.getProfile,
        method: "GET",
      }),
    }),
    getCms: builder.query<CommonResponse, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),
    updateCms: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.cms,
        method: "PUT",
        body,
      }),
    }),

    logoutApi: builder.query<CommonResponse, {}>({
      query: () => ({
        url: END_POINTS.logout,
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  useLazyGetCmsQuery,
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
  usePostResetPasswordMutation,
  usePutUpdateProfileMutation,
  useLazyGetProfileQuery,
  usePostChangePasswordMutation,
  usePostSignUpMutation,
  useUpdateCmsMutation,
  useLazyLogoutApiQuery,
} = authApi;
