// @ts-nocheck
import { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { Upload } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import { ContentHeader } from "../../../components";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import { UploadOutlined } from "@ant-design/icons";
import {
  useAddSubCategoryMutation,
  useLazyGetSubCategoryByIdQuery,
  useUpdateSubCategoryMutation,
} from "../../../services/product";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import DynamicInput from "../../../components/DynamicInput";

type ListType = {
  type: any;
  name: string;
  checked: boolean;
  disabled?: boolean | undefined;
};

type brandNameType = {
  name: string;
};

const ProductSubCategoryForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { id, view } = useParams();

  const inputRef = useRef();
  const selectRef = useRef();

  const [addSubCategoryMethod] = useAddSubCategoryMutation();
  const [getSubCategoryByIdMethod] = useLazyGetSubCategoryByIdQuery();
  const [updateSubCategoryMethod] = useUpdateSubCategoryMutation();

  const [categoryImage, setCategoryImage] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [brandsData, setBrandsData] = useState<brandNameType[]>([]);
  const [brandName, setBrandName] = useState("");
  const [selectedItems, setSelectedItems] = useState<ListType[]>([]);
  const [formValues, setFormValues] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [categoryId, setCategoryId] = useState(view === "add" ? id : "");
  const [itemList, setItemList] = useState<ListType[]>([
    {
      name: "Brand",
      checked: false,
      type: "string",
    },
  ]);

  console.log(itemList, selectedItems, formValues, brandsData, ">>>>>>");

  const getSubCategoryDetailById = async () => {
    try {
      if (id) {
        const res = await getSubCategoryByIdMethod(id).unwrap();

        if (res?.statusCode === 200) {
          form.setFieldsValue({
            image: res?.data?.image,
            categoryName: res?.data?.name,
          });
          setSubCategoryName(res?.data?.name);
          setCategoryImage(res?.data?.image);
          setCategoryId(res?.data?.parent?._id);
          if (res?.data?.dynamicFields?.length) {
            // this is to get all the selected items
            const newArr = res?.data?.dynamicFields?.map(
              (item: { [x: string]: any }) => ({
                name: Object.keys(item)[0],
                type: item[Object.keys(item)[0]],
                checked: true,
              })
            );

            setSelectedItems(newArr);

            // this is to add items which are already present in itemList and its checked is true
            const updateItemList = itemList.map((item) => ({
              name: item.name,
              checked: res?.data?.dynamicFields?.some(
                (inputItem: { [x: string]: any }) =>
                  inputItem[item.name] === item.type
              ),
              type: item.type,
            }));

            // this is to add items in itemList which are not already present
            res?.data?.dynamicFields.forEach(
              (dynamicFieldItem: { [x: string]: any }) => {
                const isItemInList = itemList.some(
                  (item) =>
                    item.name === Object.keys(dynamicFieldItem)[0] &&
                    item.type ===
                      dynamicFieldItem[Object.keys(dynamicFieldItem)[0]]
                );

                if (!isItemInList) {
                  updateItemList.push({
                    name: Object.keys(dynamicFieldItem)[0],
                    checked: true,
                    type: dynamicFieldItem[Object.keys(dynamicFieldItem)[0]],
                  });
                }
              }
            );

            setItemList(updateItemList);
          }
          if (res?.data?.brand?.length) {
            setBrandsData(res?.data?.brand?.map((item) => ({ name: item })));
          }
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const brandItem = selectedItems?.find(
      (item) => item.name === "Brand" && item.checked === true
    );

    if (brandItem && !brandsData?.length) {
      errorToast("Add brands first");
      return;
    }

    const outputArray = selectedItems
      .filter((item) => item.checked)
      .map((item) => ({
        [item.name]: item.type,
      }));

    const data = {
      name: values.categoryName,
      image: categoryImage,
      parent: categoryId,
      dynamicFields: outputArray,
      brand: brandsData.map((item) => item.name),
    };
    console.log(data, "sub category form body");
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      let res;
      view === "edit"
        ? (res = await updateSubCategoryMethod({
            body: encryptedBody,
            subCatId: id,
          }).unwrap())
        : (res = await addSubCategoryMethod({ body: encryptedBody }).unwrap());
      navigate(`/product-categories/sub-category/${categoryId}`, {
        replace: true,
      });
      if (res?.statusCode === 200) {
        successToast(
          view === "add"
            ? "Sub Category added successfully"
            : "Sub Category updated successfully"
        );
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleImageUpload = async (file: File) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setCategoryImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  const handleItemCheckboxChange = (index: number) => {
    const updatedItems = [...itemList];
    updatedItems[index].checked = !updatedItems[index].checked;
    const updatedSelectedItems = updatedItems.filter((item) => item.checked);
    setSelectedItems(updatedSelectedItems);

    // If the unchecked item is "Brand", you can clear the brand-related state.
    if (updatedItems[index].name === "Brand" && !updatedItems[index].checked) {
      setBrandsData([]);
      setBrandName("");
      form.setFieldsValue({
        brandName: "",
      });
    }
  };

  const addBrandName = () => {
    if (brandName) {
      const newData = {
        name: brandName,
      };
      const newDataValue = [...brandsData, newData];
      setBrandsData(newDataValue);
      setBrandName("");
      form.setFieldsValue({
        brandName: "",
      });
    }
  };
  const removeBrandName = (index: number) => {
    let arr1 = [];
    arr1.push(...brandsData.slice(0, index), ...brandsData.slice(index + 1));
    setBrandsData([...arr1]);
  };

  const handleAddField = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (inputRef.current.value) {
      const newField = {
        label: inputRef.current.value || "",
        type: selectRef.current.value || "string",
        value: "",
      };
      setFormValues([...formValues, newField]);
      setToggle(false);

      const newFieldForItemList = {
        name: newField.label,
        checked: true,
        type: newField.type,
      };
      setItemList([...itemList, newFieldForItemList]);
      setSelectedItems([...selectedItems, newFieldForItemList]);
    }
  };

  const handleRemoveItem = (index, ele) => {
    const updatedItemList = [...itemList];
    updatedItemList.splice(index, 1);
    setItemList(updatedItemList);

    // Remove the item from selectedItems and mark it as unchecked (false)
    const updatedSelectedItems = selectedItems.map((item) => {
      if (ele?.name === item?.name) {
        return {
          ...item,
          checked: false,
        };
      }
      return item;
    });
    setSelectedItems(updatedSelectedItems);

    const updatedFormValues = formValues.filter(
      (item) => item.label !== itemList[index].name
    );
    setFormValues(updatedFormValues);
  };

  const addNewFieldClick = (e) => {
    e.preventDefault();
    setToggle(true);
  };

  useEffect(() => {
    if (view === "edit") {
      getSubCategoryDetailById();
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo={`product-categories/sub-category/${categoryId}`}
        heading={view === "add" ? "Add Sub Category" : "Edit Sub Category"}
      />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="image"
                  rules={[
                    {
                      required: true,
                      message: "Please upload an image",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {categoryImage ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={categoryImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="categoryName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter sub category name",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Please enter only alphabets",
                    },
                  ]}
                >
                  <div>
                    <h4>Sub Category Name</h4>
                    <Input
                      placeholder="Sub Category Name"
                      value={subCategoryName}
                      style={{ height: 55 }}
                      onChange={(e) => {
                        setSubCategoryName(e.target.value);
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <DynamicInput
              handleAddField={handleAddField}
              inputRef={inputRef}
              selectRef={selectRef}
              addNewFieldClick={addNewFieldClick}
              toggle={toggle}
              removeBrandName={removeBrandName}
              handleItemCheckboxChange={handleItemCheckboxChange}
              itemList={itemList}
              Form={Form}
              brandName={brandName}
              setBrandName={setBrandName}
              addBrandName={addBrandName}
              brandsData={brandsData}
              handleRemoveItem={handleRemoveItem}
            />

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ marginTop: 20 }}>
                <Button
                  style={{
                    background: "#C69320",
                    width: 200,
                    height: 50,
                    margin: "auto",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  {view === "add" ? "Add" : "Save"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default ProductSubCategoryForm;
