import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  useLazyGetDonationAdminDetailsQuery,
  useDeleteOrganizationMutation,
  usePutUpdateOrganizationRequestMutation,
  usePutBlockOrganizationMutation,
  useLazyGetOrganizationCsvQuery,
} from "../../services/donation";
import { Donation } from "../../types/General";

import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useAuth from "../../hooks/useAuth";

const { confirm } = Modal;

const requests = [
  { name: "Accept", id: "ACCEPTED" },
  { name: "Decline", id: "REJECTED" },
  // { name: "Pending", id: "PENDING" },
];

interface DataType {
  docsApproved: any;
  key: string;
  name: string;
  phoneno: string;
  userName: string;
  email: string;
  status: boolean;
  _id: string;
}

const ManageOrganization = () => {
  const navigate = useNavigate();
  const { Option } = Select;
  const [donationAdminData, donationAdminDataQuery] =
    useLazyGetDonationAdminDetailsQuery();
  const [deleteOrganization, deleteOrganizationMutation] =
    useDeleteOrganizationMutation();
  const [handleDonationRequest, handleDonationRequestMutation] =
    usePutUpdateOrganizationRequestMutation();
  const [blockOrganization] = usePutBlockOrganizationMutation();
  const [organizationCsv] = useLazyGetOrganizationCsvQuery();

  const [selectedRequest, setSelectedRequest] = useState<string>();
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>(0);
  const [donationAdminDetails, setDonationAdminDetails] = useState<
    Donation[] | undefined
  >([]);
  const [loading, setLoading] = useState(false);
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this organization?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Manage Donation Organization"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteOrganization({ organizationId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Organization Deleted Successfully");
        getDonationAdminDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleRequest = async (_id: string, value: string) => {
    let data = {
      docsApproved: value || "",
    };

    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const res = await handleDonationRequest({
        encryptedBody,
        organizationId: _id,
      }).unwrap();
      if (res?.statusCode === 200) {
        getDonationAdminDetails(currentPage);
        successToast("Request status changed successfully");
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleBlock = async (isBlocked: boolean, _id: string) => {
    let data = {
      isBlocked: isBlocked,
    };
    console.log(isBlocked, _id, "both");
    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const res = await blockOrganization({
        encryptedBody,
        organizationId: _id,
      }).unwrap();
      if (res?.statusCode === 200) {
        getDonationAdminDetails(currentPage);
        successToast(
          isBlocked
            ? "Organization Deactivated Successfully!"
            : "Organization Activated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const columns: ColumnsType<Donation> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, { name }: any) => (
        <div>
          <span>{name || "-"}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, { email }: any) => (
        <div>
          <span>{email || "-"}</span>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneno",
      key: "phoneno",
      align: "center",
      render: (_, { phone, countryCode }: any) => (
        <div>
          <span>{`${countryCode || "-"} ${phone}`}</span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-organization/details/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/manage-organization/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : (
            ""
          )}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Request Status",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Space
          size="middle"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Select
            // allowClear
            maxLength={20}
            className="select_type withInput_small"
            onChange={(value) => {
              handleRequest(record?._id, value);
              setSelectedRequest(value);
            }}
            value={
              record?.docsApproved
                ? record.docsApproved.charAt(0).toUpperCase() +
                  record.docsApproved.slice(1).toLowerCase()
                : ""
            }
            placeholder="Request Status"
            // defaultValue={}
          >
            {requests?.map((item, index) => (
              <Option key={index} value={item?.id} label={item?.name}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => handleBlock(!isBlocked, _id)}
        />
      ),
    },
  ];

  const getDonationAdminDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await donationAdminData({
        page: page,
        size: 10,
        search: query || "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setDonationAdminDetails(res?.data?.donationAdmin);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleExportCsv = async () => {
    try {
      const res = await organizationCsv({}).unwrap();
      if (res?.statusCode === 200) {
        if (res?.data) {
          window.open(res?.data);
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    getDonationAdminDetails(1);
  }, [query]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getDonationAdminDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Manage Donation Organization" />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Form style={{ display: "flex", gap: 20 }}>
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  value={debouncedQuery}
                  onChange={(e) => setDebouncedQuery(e.target.value)}
                  maxLength={20}
                />
              </Form>
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <Button
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/manage-organization/add`);
                }}
              >
                Add Organization
              </Button>
              <Button
                onClick={handleExportCsv}
                icon={<DownloadOutlined />}
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
              >
                Export CSV
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={donationAdminDetails}
            pagination={pagination}
            loading={
              donationAdminDataQuery?.isLoading ||
              handleDonationRequestMutation?.isLoading ||
              deleteOrganizationMutation?.isLoading ||
              loading
            }
          />
        </Card>
      </div>
    </div>
  );
};

export default ManageOrganization;
