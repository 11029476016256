import { ContentHeader } from "../../components";
import EventCategoryList from "./category";

const EventCategories = () => {
  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Event Categories" />
      <EventCategoryList />
    </div>
  );
};

export default EventCategories;
