import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";

import {
  EyeOutlined,
  DownloadOutlined,
  DeleteOutlined,
  SearchOutlined,
  EditFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ContentHeader } from "../../components";
import {
  useDeleteOwnerMutation,
  useLazyGetOwnerCsvQuery,
  useLazyGetOwnerDetailsQuery,
  usePutBlockOwnerMutation,
  usePutUpdateOwnerRequestMutation,
} from "../../services/owner";
import { CommonBody } from "../../types/General";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useAuth from "../../hooks/useAuth";

const { confirm } = Modal;

interface DataType {
  key: string;
  name: string;
  phoneno: string;
  userName: string;
  email: string;
  status: boolean;
  _id: string;
  docsApproved?: string;
}

const ManageEventOwner = () => {
  const [selectedRequest, setSelectedRequest] = useState<string>();
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [form] = Form.useForm();
  const [ownerDetails, setOwnerDetails] = useState();
  const [ownerList, ownerListQuery] = useLazyGetOwnerDetailsQuery();
  const [blockOwner, blockOwnerMutation] = usePutBlockOwnerMutation();
  const [deleteOwner, deleteOwnerMutation] = useDeleteOwnerMutation();
  const [getOwnerCsvMethod] = useLazyGetOwnerCsvQuery();
  const [query, setQuery] = useState("");

  const [blockOwnerRequest, blockOwnerRequestMutation] =
    usePutUpdateOwnerRequestMutation();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  const navigate = useNavigate();
  const { Option } = Select;

  const requests = [
    { name: "Accept", id: "ACCEPTED" },
    { name: "Decline", id: "REJECTED" },
    // { name: "Pending", id: "PENDING" },
  ];

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this owner?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteOwner({ ownerId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Owner Deleted Successfully");
        getOwnerDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleRequest = async (_id: string, value: string) => {
    let data = {
      docsApproved: value || "",
    };

    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const res = await blockOwnerRequest({
        encryptedBody,
        ownerId: _id,
      }).unwrap();
      if (res?.statusCode === 200) {
        getOwnerDetails(currentPage);
        successToast("Request status changed successfully");
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleBlock = async (isBlocked: boolean, _id: string) => {
    let data = {
      isBlocked: isBlocked,
    };
    console.log(isBlocked, _id, "both");
    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const res = await blockOwner({ encryptedBody, ownerId: _id }).unwrap();
      if (res?.statusCode === 200) {
        getOwnerDetails(currentPage);
        successToast(
          isBlocked
            ? "Owner Deactivated Successfully!"
            : "Owner Activated Successfully!"
        );
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const getOwnerDetails = async (page: number) => {
    try {
      const res = await ownerList({
        page: page,
        size: 10,
        search: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setOwnerDetails(res?.data?.subAdmin);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOwnerDetails(1);
  }, [query]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Manage Event Owner"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Full Name",
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
      render: (_, { firstName, lastName }: any) => (
        <div>
          <span>{`${firstName || "-"} ${lastName || ""}`}</span>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, { email }: any) => (
        <div>
          <span>{`${email || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneno",
      key: "phoneno",
      align: "center",
      render: (_, { phone, countryCode }: any) => (
        <div>
          <span>{`${countryCode || "-"} ${phone}`}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-event-owner/details/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/manage-event-owner/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : (
            ""
          )}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Request Status",
      dataIndex: "email",
      key: "email",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Space size="middle">
          <Select
            maxLength={20}
            className="select_type withInput_small"
            onChange={(value) => {
              handleRequest(record?._id, value);
              setSelectedRequest(value);
            }}
            value={
              record?.docsApproved
                ? record.docsApproved.charAt(0).toUpperCase() +
                  record.docsApproved.slice(1).toLowerCase()
                : ""
            }
            placeholder="Request Status"
            // defaultValue={}
          >
            {requests?.map((item, index) => (
              <Option key={index} value={item?.id} label={item?.name}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Space>
      ),
    },
    {
      title: "Account Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { isBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => handleBlock(!isBlocked, _id)}
        />
      ),
    },
  ];
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getOwnerDetails(page);
  };

  const handleExportCsv = async () => {
    try {
      const res = await getOwnerCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        if (res?.data) {
          window.open(res?.data);
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Manage Event Booking" />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
              />
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              {hidePermission?.isAdd ? (
                <Button
                  style={{
                    background: "#ECAC35",
                    height: 35,
                    color: "black",
                  }}
                  type="primary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/manage-event-owner/add`);
                  }}
                >
                  Add Owner
                </Button>
              ) : (
                ""
              )}
              <Button
                onClick={handleExportCsv}
                icon={<DownloadOutlined />}
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
              >
                Export CSV
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            rowKey="_id"
            dataSource={ownerDetails}
            pagination={pagination}
            loading={
              ownerListQuery?.isLoading ||
              blockOwnerMutation?.isLoading ||
              deleteOwnerMutation?.isLoading ||
              blockOwnerRequestMutation?.isLoading
            }
          />
        </Card>
      </div>
    </div>
  );
};

export default ManageEventOwner;
