import { Button, Card, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AddRole from "../../../components/manageSubAdmin/AddRole";
import FormData from "../../../components/manageSubAdmin/Form";

const { TabPane } = Tabs;

const SubAdminForm = () => {
  const navigate = useNavigate();
  const url = window.location.pathname;
  const path = url.includes("add");

  const [activeTabKey, setActiveTabKey] = useState("1");

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <div style={{ padding: 40 }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: "10px"
            
          }}
        >
          <h2>{path ? "Add " : "Edit "}Sub Admin</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/managesubadmins", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        {path ? (
          <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
            <TabPane tab="Add Role" key="1">
              <AddRole />
            </TabPane>
            <TabPane tab="Add Sub Admin" key="2">
              <FormData path={path} />
            </TabPane>
          </Tabs>
        ) : (
          <FormData path={path} />
        )}
      </Card>
    </div>
  );
};

export default SubAdminForm;
