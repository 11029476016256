import { Card, Col, Row, Select } from "antd";
import { LineChart } from ".";

const UsersChart = (props: any) => {
  const handleFilterChangeUser = (value: string) => {
    props.onFilterChange(value);
    props.setName("right");
  };

  return (
    <div>
      <Card className="chart_card">
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2 style={{ margin: 0, fontSize: 19 }}>Total Users Revenue</h2>
          <div style={{ width: "20%" }}>
            <Select
              style={{ width: "100%" }}
              onChange={handleFilterChangeUser}
              defaultValue={"monthly"}
              placeholder="Filter"
              options={[
                { value: "daily", label: "Daily" },
                { value: "weekly", label: "Weekly" },
                { value: "monthly", label: "Monthly" },
                { value: "yearly", label: "Yearly" },
              ]}
            />
          </div>
        </div>
        <div style={{ margin: "auto" }} className="graph_ch">
          <LineChart data={props.graphCustomerData} />
        </div>
      </Card>
    </div>
  );
};

export default UsersChart;
