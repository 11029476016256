import { API_URL, END_POINTS, STORAGE_KEYS, getFromStorage } from "../helpers";

export const UploadMedia = async (imageObject: any) => {
  const formData = new FormData();

  formData.append("image", imageObject as any);

  const getToken = await getFromStorage(STORAGE_KEYS.token);
  const token = getToken ? JSON.parse(`${getToken}`) : null;
  let headers = {
    // "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };
  try {
    const res = await fetch(API_URL + END_POINTS.mediaUpload, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    return response;
  } catch (error) {
    // console.log(error, ">>>>>>>>>");
    return error;
  }
};
