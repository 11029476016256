import { Col, Input, Row } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

const EventUsersTable = () => {
  interface DataType {
    key: string;
    startDate: string;
    endDate: string;
    totalMatches: string;
    name: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "Contact Details",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
    },
    {
      title: "Attendee Name",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
    },
    {
      title: "A Number Of Purchased Tickets",
      dataIndex: "totalMatches",
      key: "totalMatches",
      align: "center",
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: "mike",
      startDate: "73648736487",
      endDate: "sam",
      totalMatches: "9",
    },
    {
      key: "2",
      name: "mike",
      startDate: "73648736487",
      endDate: "sam",
      totalMatches: "9",
    },
    {
      key: "3",
      name: "mike",
      startDate: "73648736487",
      endDate: "sam",
      totalMatches: "9",
    },
    {
      key: "4",
      name: "mike",
      startDate: "73648736487",
      endDate: "sam",
      totalMatches: "9",
    },
    {
      key: "5",
      name: "Tournament",
      startDate: "73648736487",
      endDate: "sam",
      totalMatches: "9",
    },
  ];

  return (
    <div style={{}}>
      <div className="srch">
        <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}>
          <Col xs={24} md={7}>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
              style={{ height: 50, borderRadius: 10 }}
            />
          </Col>
        </Row>
        <Table columns={columns} dataSource={data} rowKey="_id" />
      </div>
    </div>
  );
};

export default EventUsersTable;
