import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Table,
} from "antd";
import type { ColumnsType } from "antd/es/table";

import { DeleteOutlined, SearchOutlined, EditFilled } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage, ScrollPosition } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../helpers";
import { CommonBody, SubCategory } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import useAuth from "../../../hooks/useAuth";
import { ContentHeader } from "../../../components";
import {
  useDeleteSubCategoryMutation,
  useLazyGetSubCategoryListQuery,
  useUpdateSubCategoryMutation,
} from "../../../services/product";

const { confirm } = Modal;

type PropTypes = {
  scrollPosition?: ScrollPosition;
};

const ProductSubCategoryList = ({ scrollPosition }: PropTypes) => {
  const userData = useAuth();

  const { id } = useParams();

  const [getSubCategoryMethod] = useLazyGetSubCategoryListQuery();
  const [deleteSubCategory] = useDeleteSubCategoryMutation();
  const [updateSubCategory] = useUpdateSubCategoryMutation();

  const [allSubCategoryData, setAllSubCategoryData] = useState<SubCategory[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>(0);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const getSubCategoryDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getSubCategoryMethod({
        page: page,
        size: 10,
        search: query || "",
        categoryId: id,
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllSubCategoryData(res?.data?.subCategory);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSubCategoryStatus = async (isBlocked: boolean, _id: string) => {
    const data = {
      isBlocked: isBlocked,
    };

    console.log(isBlocked, _id, "isActive");

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await updateSubCategory({
        body: encryptedBody,
        subCatId: _id,
      }).unwrap();
      if (res?.statusCode === 200) {
        successToast(
          isBlocked
            ? "Sub Category Deactivated Successfully!"
            : "Sub Category Activated Successfully!"
        );
        getSubCategoryDetails(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteSubCategory({ subCatId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Sub Category deleted successfully");
        getSubCategoryDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this sub category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Product Categories"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const columns: ColumnsType<SubCategory> = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      render: (_, { image }) => (
        <div>
          {image ? (
            <LazyLoadImage
              alt="img"
              style={{ width: 40, height: 40, borderRadius: 5 }}
              src={image?.[0]}
              effect="blur"
              scrollPosition={scrollPosition}
            />
          ) : (
            "N/A"
          )}
        </div>
      ),
    },

    {
      title: "Sub Category Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `/product-categories/sub-category/edit/${record?._id}`,
                  {
                    state: { subCatId: record?._id },
                  }
                );
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : (
            ""
          )}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "deviation",
      align: "center",
      render: (_, { isBlocked, _id }: any) => (
        <Switch
          size="small"
          defaultChecked={!isBlocked}
          onChange={() => handleSubCategoryStatus(!isBlocked, _id)}
        />
      ),
    },
  ];

  useEffect(() => {
    getSubCategoryDetails(1);
  }, [query]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getSubCategoryDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);
    return () => {
      clearTimeout(timerId);
    };
  }, [debouncedQuery]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Product Sub Categories"
        buttonText="Back"
        buttonNavigateTo="product-categories"
      />

      <div className="srch">
        <Card>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Input
                placeholder="Search Sub Category"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
                allowClear
              />
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/product-categories/sub-category/add/${id}`);
                }}
              >
                Add Sub Category
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={allSubCategoryData}
            pagination={pagination}
            loading={loading}
            rowKey="_id"
          />
        </Card>
      </div>
    </div>
  );
};

export default ProductSubCategoryList;
