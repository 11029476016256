import { Button, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetUserDetailsIdQuery } from "../../../services/user";
import { Customers } from "../../../types/User";

const UserDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [getUser, getUserData] = useLazyGetUserDetailsIdQuery();
  const [userDetail, setUserDetail] = useState<Customers>();

  const getUserDetails = async () => {
    try {
      const res = await getUser(id).unwrap();

      if (res?.statusCode === 200) {
        setUserDetail(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card loading={getUserData?.isLoading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>User details</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manageusers", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12}>
              <h4>User image</h4>
              <div>
                <img
                  style={{
                    height: 100,
                    width: 100,
                    borderRadius: "50%",
                    marginTop: 10,
                  }}
                  src={userDetail?.image || "/static/images/dummy.png"}
                  alt=""
                />
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Full Name</h4>
              <h5>
                {userDetail?.firstName || "-"} {userDetail?.lastName || ""}
              </h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>{userDetail?.email || "-"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>User Earning</h4>
              <h5>{userDetail?.userEarning || "-"}</h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Phone Number</h4>
              <h5>
                {userDetail?.countryCode || "-"} {userDetail?.phone || ""}
              </h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Address</h4>
              <h5>{userDetail?.address || "-"}</h5>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
};

export default UserDetails;
