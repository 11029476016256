import { END_POINTS } from "../helpers";
import {
  CommonBody,
  EventBooking,
  EventCategory,
  Events,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: {
    count: number;
    event: Events[] | [];
    booking: EventBooking[];
  };
  message: string;
};

type EventCategoryResponse = {
  statusCode: number;
  data?: { eventCategory: EventCategory[] };
  message: string;
};

type EditParams = {
  body: CommonBody;
  eventId: string;
};
type AddParams = {
  body: CommonBody;
  ownerId: string;
};

export const eventApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addEvents: builder.mutation<CommonResponseType, AddParams>({
      query: ({ body, ownerId }) => ({
        url: END_POINTS.events + "?ownerId=" + ownerId,
        method: "POST",
        body,
      }),
    }),

    editEvents: builder.mutation<CommonResponseType, EditParams>({
      query: ({ body, eventId }) => ({
        url: `${END_POINTS.events}/${eventId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteEvents: builder.mutation<CommonResponseType, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `${END_POINTS.events}/${eventId}`,
        method: "DELETE",
      }),
    }),

    getAllEvents: builder.query<
      CommonResponseType,
      { page?: number; query: string; activeTab?: number; size?: number }
    >({
      query: ({ page, query, activeTab, size }) => ({
        url: `${END_POINTS.events}?page=${page || false}&size=${
          size || ""
        }&type=${activeTab || ""}&search=${query}`,
        method: "GET",
      }),
    }),
    getAllEventBookings: builder.query<
      CommonResponseType,
      {
        page: number;
        query: string;
        size: number;
        activeTab: number;
        eventId: string | undefined;
      }
    >({
      query: ({ page, query, size, activeTab, eventId }) => ({
        url: `${
          END_POINTS.eventBooking
        }?page=${page}&size=${size}&search=${query}&type=${activeTab}${
          eventId ? `&eventId=${eventId}` : ""
        }`,
        method: "GET",
      }),
    }),

    getEventBookingById: builder.query<
      CommonResponseType & { data: EventBooking },
      { eventId: string }
    >({
      query: ({ eventId }) => ({
        url: `${END_POINTS.eventBooking}/${eventId}`,
        method: "GET",
      }),
    }),

    getEventById: builder.query<
      CommonResponseType & { data: Events },
      { eventId: string }
    >({
      query: ({ eventId }) => ({
        url: `${END_POINTS.events}/${eventId}`,
        method: "GET",
      }),
    }),

    getEventsCategory: builder.query<EventCategoryResponse, {}>({
      query: () => ({
        url: END_POINTS.eventCategory + "?size=50&page=1",
        method: "GET",
      }),
    }),
    eventStatusUpdate: builder.mutation<
      EventCategoryResponse,
      { body: CommonBody; bookingId: string }
    >({
      query: ({ body, bookingId }) => ({
        url: `${END_POINTS.cancelEventBooking}/${bookingId}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useAddEventsMutation,
  useEditEventsMutation,
  useLazyGetAllEventsQuery,
  useLazyGetEventByIdQuery,
  useDeleteEventsMutation,
  useLazyGetEventsCategoryQuery,
  useEventStatusUpdateMutation,
  useLazyGetAllEventBookingsQuery,
  useLazyGetEventBookingByIdQuery,
} = eventApi;
