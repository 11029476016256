import { useState, useEffect } from "react";
import { Notification } from "../../../types/General";
import { Button, Card, Modal, Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import {
  useDeleteNotificationMutation,
  useLazyGetNotificationsQuery,
} from "../../../services/notifications";
import { DeleteOutlined } from "@ant-design/icons";
import { errorToast, successToast } from "../../../helpers";

const NotificationsHistory = () => {
  const navigate = useNavigate();
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getNotificationsDetails(page);
  };

  const [query, setQuery] = useState("");
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [notificationList, setNotificationList] = useState<
    Notification[] | undefined
  >([]);
  const [notifications, notificationsData] = useLazyGetNotificationsQuery();
  const [deleteNotification, deleteNotificationMutation] =
    useDeleteNotificationMutation();
  const { confirm } = Modal;

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteNotification({ id: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast(" Deleted Successfully");
        getNotificationsDetails(1);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this notification?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const getNotificationsDetails = async (page: number) => {
    try {
      const res = await notifications({
        page: page,
        size: 10,
        query: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setNotificationList(res?.data?.notification);
        setTotalCount(res?.data?.notificationCount);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (query === "") {
      getNotificationsDetails(1);
    }
  }, [query]);

  interface DataType {
    key: string;
    title: string;
    message: string;
    serial: number;
    createdAt: string;
  }

  const columns: ColumnsType<Notification> = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      width: "5%",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "15%",
    },
    {
      title: "Description",
      dataIndex: "message",
      key: "message",
      width: "50%",
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "20%",
      render: (_, record) => (
        <div>
          <span>
            {moment(record?.createdAt).format("MMMM Do YYYY, h:mm a")}
          </span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",

      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record);
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  return (
    <div style={{ padding: 50 }}>
      <Card style={{ overflowX: "scroll" }}>
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Notification History</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              onClick={() => {
                navigate("/notifications", { replace: true });
              }}
            >
              Back
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={notificationList}
            pagination={pagination}
            loading={
              notificationsData?.isLoading ||
              deleteNotificationMutation?.isLoading
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default NotificationsHistory;
