import { useEffect, useState } from "react";
import { Card, Col, Input, Row, Button, Space, Modal, Form } from "antd";
import { ContentHeader } from "../../components";
import {
  DeleteOutlined,
  EditFilled,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

import {
  useLazyGetProductQuery,
  useDeleteProductMutation,
} from "../../services/product";
import { errorToast, successToast } from "../../helpers";
import useAuth from "../../hooks/useAuth";

const { confirm } = Modal;

const ListedProductPost = () => {
  const [getProduct, getProductData] = useLazyGetProductQuery();
  const [deleteProduct] = useDeleteProductMutation();
  const [AllProductsData, setAllProductsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  const navigate = useNavigate();

  const getProductDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getProduct({
        page: page,
        size: 10,
        search: query || "",
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setAllProductsData(res?.data?.product);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this product?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteProduct({ productId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Product Deleted Successfully");

        getProductDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.label === "Listed Products Posts"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  interface DataType {
    key: string;
    score: string;
    nunberOfTokens: string;
    winners: string;
    rank: string;
    name: string;
    price: string;
    _id: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Product name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, { name }: any) => (
        <div>
          <span>{`${name || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Price of the Product",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      render: (_, { price }) => (
        <div>
          <span>{`${price || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Category of the Product",
      dataIndex: "category",
      key: "category",
      align: "center",
      render: (_, { categoryId }: any) => (
        <div>
          <span>{`${categoryId?.name || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Sub Category of the Product",
      dataIndex: "subCategoryId",
      key: "subCategoryId",
      align: "center",
      render: (_, { subCategoryId }: any) => (
        <div>
          <span>{`${subCategoryId?.name || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/listed-products-posts/details/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          {/* {hidePermission?.isAdd ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate(`/listed-products-posts/${record?._id}`);
              }}
            >
              <EditFilled style={{ color: "#C69320" }} />
            </Button>
          ) : (
            ""
          )} */}
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    getProductDetails(1);
  }, [query]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getProductDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [debouncedQuery]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Listed Product Post" />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row style={{ padding: "10px 0px" }} gutter={16}>
            <Col xs={24} md={7}>
              <Form style={{ display: "flex", gap: 20 }}>
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  value={debouncedQuery}
                  onChange={(e) => setDebouncedQuery(e.target.value)}
                  maxLength={20}
                  allowClear
                />
              </Form>
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={AllProductsData}
            rowKey="_id"
            loading={loading}
            pagination={pagination}
          />
        </Card>
      </div>
    </div>
  );
};

export default ListedProductPost;
