import { Button, Card, Col, Row, Spin } from "antd";
import { Key, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetShopDetailsIdQuery } from "../../../services/shops";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ShopDetails = () => {
  const { id } = useParams();

  const [getShopDataById, getProductDataByIdQuery] =
    useLazyGetShopDetailsIdQuery();
  const [productDetail, setProductDetail] = useState<any>();
  const [loading, setLoading] = useState(false);

  const getShopDetailById = async () => {
    try {
      setLoading(true);
      const res = await getShopDataById(id).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setProductDetail(res?.data);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getShopDetailById();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Shop details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/manage-shops", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Product Name</h4>
                <h5>{productDetail?.name || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Description</h4>
                <h5>{productDetail?.description || "-"}</h5>
              </Col>
            </Row>

            <h2>Images</h2>
            <div>
              <Row gutter={16}>
                <Col sm={24} xs={24} md={12}>
                  <div
                    style={{
                      display: "flex",
                      gap: 20,
                    }}
                  >
                    {productDetail?.images?.map(
                      (
                        item: string | undefined,
                        index: Key | null | undefined
                      ) => (
                        <LazyLoadImage
                          effect="blur"
                          key={index}
                          style={{
                            height: 200,
                            borderRadius: 10,
                            width: 200,
                          }}
                          src={item}
                          alt="img"
                        />
                      )
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default ShopDetails;
