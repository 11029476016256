import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader } from "../../components";
import { useLazyGetFundRaisingEventQuery } from "../../services/donation";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface DataType {
  key: string;
  name: string;
  date: string;
  email: string;
  status: boolean;
  item: string;
  createdAt: string;
  campaign: string;
  _id: string;
}

const FundRaisingBooking = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>(0);
  const [loading, setLoading] = useState(false);
  const [fundRaisingBookingData] = useLazyGetFundRaisingEventQuery();
  const [fundRaisingData, setFundRaisingData] = useState([]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Event Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, { name }: any) => (
        <div>
          <span>{name}</span>
        </div>
      ),
    },
    {
      title: "Money Goal",
      dataIndex: "campaign",
      key: "campaign",
      align: "center",
      render: (_, { moneyGoal }: any) => (
        <div>
          <span>{moneyGoal}</span>
        </div>
      ),
    },
    {
      title: "Event Date Time",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (_, { createdAt }) => (
        <div>
          <span>{moment(createdAt).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/fund-raising/details/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const getFundRaisingDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await fundRaisingBookingData({
        page: page,
        size: 10,
        search: query || "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setFundRaisingData(res?.data?.fundRaising);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [debouncedQuery]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getFundRaisingDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    getFundRaisingDetails(1);
  }, [query]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Fund Raising Events" />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Form
                style={{ display: "flex", gap: 20 }}
                onFinish={() => getFundRaisingDetails(1)}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                  style={{ height: 50, borderRadius: 10 }}
                  value={debouncedQuery}
                  onChange={(e) => setDebouncedQuery(e.target.value)}
                  maxLength={20}
                />
              </Form>
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            ></Col>
          </Row>
          <Table
            columns={columns}
            dataSource={fundRaisingData}
            pagination={pagination}
            loading={loading}
          />
        </Card>
      </div>
    </div>
  );
};

export default FundRaisingBooking;
