import { Button, Card, Col, Form, Input, Row } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
interface ticketType {
  category: string;
  price: string;
  count: string;
  _id?: number | undefined;
}
interface ManageProps {
  ticketsValue: ticketType[];
  setTicketsValue: Dispatch<SetStateAction<ticketType[]>>;
  ticketCat: string;
  setTicketCat: Dispatch<SetStateAction<string>>;
  ticketCount: string;
  setTicketCount: Dispatch<SetStateAction<string>>;
  ticketPrice: string;
  setTicketPrice: Dispatch<SetStateAction<string>>;
}
const TicketsForm = ({
  ticketsValue,
  setTicketsValue,
  ticketCat,
  setTicketCat,
  ticketCount,
  setTicketCount,
  ticketPrice,
  setTicketPrice,
}: ManageProps) => {
  const [editingTickets, setEditingTickets] = useState<ticketType | null>(null);
  const [nextTicketId, setNextTicketId] = useState(1);
  const addField = () => {
    const newTicket = {
      price: ticketPrice,
      count: ticketCount,
      category: ticketCat,
      _id: nextTicketId,
    };
    setNextTicketId(nextTicketId + 1);
    const newTicketsValue = [...ticketsValue, newTicket];
    setTicketsValue(newTicketsValue);
    setTicketCat("");
    setTicketCount("");
    setTicketPrice("");
  };
  const removeField = (index: number) => {
    let arr1 = [];
    arr1.push(
      ...ticketsValue.slice(0, index),
      ...ticketsValue.slice(index + 1)
    );
    setTicketsValue([...arr1]);
  };
  const editTicketHandler = (i: any) => {
    const selectedTicket = ticketsValue.find((item) => item === i);
    if (selectedTicket) {
      setEditingTickets(selectedTicket);
      setTicketCat(selectedTicket.category);
      setTicketCount(selectedTicket.count);
      setTicketPrice(selectedTicket.price);
    }
  };
  const updateTicketHandler = () => {
    if (editingTickets) {
      const index = ticketsValue.findIndex(
        (item) => item._id === editingTickets._id
      );
      if (index !== -1) {
        const updatedTicketsValue = [...ticketsValue];
        updatedTicketsValue[index] = {
          category: editingTickets.category,
          price: editingTickets.price,
          count: editingTickets.count,
          _id: editingTickets._id,
        };
        setTicketsValue(updatedTicketsValue);
        setEditingTickets(null);
        setTicketCat("");
        setTicketCount("");
        setTicketPrice("");
      }
    }
  };
  return (
    <Card>
      <h3>Ticket Details</h3>
      <div>
        {ticketsValue?.map((item, index) => (
          <Row key={index} gutter={16} align="bottom">
            <Col xs={20} md={6}>
              <Form.Item name="ticketCategory">
                <div>
                  <h4>Ticket Category</h4>
                  <Input
                    placeholder="Ticket Category"
                    style={{ height: 55 }}
                    name="category"
                    value={item?.category}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col xs={20} md={6}>
              <Form.Item name="ticketCount">
                <div>
                  <h4>Ticket Count</h4>
                  <Input
                    placeholder="Ticket Count"
                    style={{ height: 55 }}
                    type="number"
                    name="count"
                    value={item?.count}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col xs={20} md={6}>
              <Form.Item name="ticketPrice">
                <div>
                  <h4>Ticket Price</h4>
                  <Input
                    placeholder="Ticket Price"
                    style={{ height: 55 }}
                    type="number"
                    name="price"
                    value={item?.price}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col xs={20} md={2}>
              <Form.Item>
                <Button onClick={() => removeField(index)}>Remove</Button>
              </Form.Item>
            </Col>
            <Col xs={20} md={2}>
              <Form.Item>
                <Button onClick={() => editTicketHandler(item)}>Edit</Button>
              </Form.Item>
            </Col>
          </Row>
        ))}
        <Row gutter={16} align="bottom">
          <Col xs={20} md={6}>
            <Form.Item name="ticketCategory">
              <div>
                <h4>Ticket Category</h4>
                <Input
                  placeholder="Ticket Category"
                  style={{ height: 55 }}
                  name="category"
                  // value={ticketCat}
                  // onChange={(e) => setTicketCat(e.target.value)}
                  value={editingTickets ? editingTickets.category : ticketCat}
                  onChange={(val) => {
                    if (editingTickets) {
                      setEditingTickets({
                        ...editingTickets,
                        category: val.target.value,
                      });
                    } else {
                      setTicketCat(val.target.value);
                    }
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={20} md={6}>
            <Form.Item name="ticketCount">
              <div>
                <h4>Ticket Count</h4>
                <Input
                  placeholder="Ticket Count"
                  style={{ height: 55 }}
                  type="number"
                  name="count"
                  value={editingTickets ? editingTickets.count : ticketCount}
                  onChange={(val) => {
                    if (editingTickets) {
                      setEditingTickets({
                        ...editingTickets,
                        count: val.target.value,
                      });
                    } else {
                      setTicketCount(val.target.value);
                    }
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={20} md={6}>
            <Form.Item name="ticketPrice">
              <div>
                <h4>Ticket Price</h4>
                <Input
                  placeholder="Ticket Price"
                  style={{ height: 55 }}
                  type="number"
                  name="price"
                  value={editingTickets ? editingTickets.price : ticketPrice}
                  onChange={(val) => {
                    if (editingTickets) {
                      setEditingTickets({
                        ...editingTickets,
                        price: val.target.value,
                      });
                    } else {
                      setTicketPrice(val.target.value);
                    }
                  }}
                />
              </div>
            </Form.Item>
          </Col>
          <Col xs={30} md={2}>
            <Form.Item>
              <Button
                disabled={!ticketCat || !ticketCount || !ticketPrice}
                onClick={editingTickets ? updateTicketHandler : addField}
              >
                {editingTickets ? "Update" : "Add"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default TicketsForm;
