import { Card, Col, Input, Row, Button, Space, Modal } from "antd";
import { ContentHeader } from "../../components";
import { DeleteOutlined, EditFilled, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useForFeatureProductsMutation,
  useLazyGetFeatureProductQuery,
} from "../../services/product";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import useAuth from "../../hooks/useAuth";

const RecommendedProducts = () => {
  const navigate = useNavigate();
  const [AllProductsData, setAllProductsData] = useState([]);
  const [getProduct, getProductQuery] = useLazyGetFeatureProductQuery();
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [deleteFeatureProduct] = useForFeatureProductsMutation();
  const [query, setQuery] = useState("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const userData = useAuth();

  interface DataType {
    key: string;

    title: string;
    name: any;

    category: string;
  }
  const { confirm } = Modal;

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this user?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(record?._id);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDelete = async (id: string) => {
    let data = { objectId: id || "", operation: "pull" };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await deleteFeatureProduct(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        successToast("Deleted Successfully");
        getProductDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permissions;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.label === "Manage Users");
      if (idx != -1) {
        if (!permissions[idx]?.isAdd) {
          let data = {
            isAdd: false,
            isDelete: hidePermission?.isDelete,
          };
          setHidePermission(data);
        }
        if (!permissions[idx]?.isDelete) {
          let data = {
            isAdd: hidePermission?.isAdd,
            isDelete: false,
          };
          setHidePermission(data);
        }
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permissions?.length) {
      checkPermission();
    }
  }, [userData]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Product name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Price of the Product",
      key: "price",
      dataIndex: "price",
      align: "center",
    },
    {
      title: "Category of the Product",
      dataIndex: "category",
      key: "category",
      align: "center",
      render: (_, { category }: any) => (
        <div>
          <span>{`${category?.name || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Sub Category of the Product",
      dataIndex: "subCategory",
      key: "subCategory",
      align: "center",
      render: (_, { subCategory }: any) => (
        <div>
          <span>{`${subCategory?.name || "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {hidePermission?.isDelete ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                showDeleteConfirm(record);
              }}
            >
              <DeleteOutlined style={{ color: "#d4380d" }} />
            </Button>
          ) : (
            "-"
          )}
        </Space>
      ),
    },
  ];

  const getProductDetails = async (page: number) => {
    try {
      const res = await getProduct({
        page: page,
        size: 10,
        search: query || "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setAllProductsData(res?.data?.recommendedProducts);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductDetails(1);
  }, [query]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getProductDetails(page);
  };
  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Featured Products"
        buttonNavigateTo="recommended-products/add"
        buttonText={hidePermission?.isAdd ? "Add Products" : ""}
      />
      <Card>
        <div className="srch">
          <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}>
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
                maxLength={20}
              />
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={AllProductsData}
            rowKey="_id"
            loading={getProductQuery?.isLoading}
            pagination={pagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default RecommendedProducts;
