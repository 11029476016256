import { Button, Card, Col, Input, Row, Select, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";
import { useState } from "react";

const RevanueManagement = () => {
  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Revenue Management" />
    </div>
  );
};

export default RevanueManagement;
