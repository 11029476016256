import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../../helpers";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { usePostForgotPasswordMutation } from "../../../services/auth";
import { CommonBody } from "../../../types/General";

const ForgetPassword = () => {
  const [postForgetPassword, postForgetPasswordMutation] =
    usePostForgotPasswordMutation();
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    const data = {
      email: values.email,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await postForgetPassword(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        successToast(res?.message);
        navigate("/verify-otp", { state: { email: values.email } });
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div onClick={() => navigate(-1)}>
          <ArrowLeftOutlined style={{ fontSize: 20, cursor: "pointer" }} />
        </div>
        <div className="login_img">
          {/* <img src="/static/images/logo.png" alt="" /> */}
        </div>
        <div className="bck_arrw_div">
          <h3>Forgot Password</h3>
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          style={{
            marginTop: 50,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "please enter valid email!",
              },
            ]}
          >
            <Input
              style={{ height: "50px" }}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  height: 50,
                  margin: "auto",
                  color: "black",
                }}
                type="primary"
                htmlType="submit"
                loading={postForgetPasswordMutation?.isLoading}
              >
                Send
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default ForgetPassword;
