import { Button, Card, Col, Row, Spin, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { ColumnsType } from "antd/es/table";
import {
  useLazyGetFundRaisingEventByIdQuery,
  useLazyGetFundRaisingBookingQuery,
} from "../../../services/donation";
import { FundBooking } from "../../../types/General";

const FundRaisingDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [funRaisingBookingQuery] = useLazyGetFundRaisingBookingQuery();
  const [fundRaisingBookingDetailById] = useLazyGetFundRaisingEventByIdQuery();
  const [fundRaisingData, setFundRaisingData] = useState<
    FundBooking | undefined
  >();
  const [eventId, setEventId] = useState("");
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number | undefined>(0);
  const [booking, setBooking] = useState([]);

  const [loading, setLoading] = useState(false);
  console.log(eventId, "Sdfsfs");

  const getFundRaisingBookingDetailsById = async () => {
    try {
      setLoading(true);
      const res = await fundRaisingBookingDetailById(id).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setFundRaisingData(res?.data);
        setEventId(res?.data?.eventId?._id);
        // getFundRaisingBooking(res?.data?.eventId?._id);
        console.log(fundRaisingData, "data");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  console.log(eventId, "eventId");

  interface DataType {
    key: string;
    name: string;
    date: string;
    createdAt: string;
    _id: string;
    paymentMode: string;
    amount: string;
    user: {
      email: string;
      countryCode: string;
      phone: string;
    };
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, { name }) => (
        <div>
          <span>{name || "-"}</span>
        </div>
      ),
    },
    {
      title: "Customer Email Id",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, { user }) => (
        <div>
          <span>{user?.email || "-"}</span>
        </div>
      ),
    },
    {
      title: "Customer Phone Number",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      render: (_, { user }) => (
        <div>
          <span>{`${user?.countryCode} ${user?.phone}` || "-"}</span>
        </div>
      ),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      align: "center",
      render: (_, { paymentMode }) => (
        <div>
          <span>{paymentMode || "-"}</span>
        </div>
      ),
    },
    {
      title: "Amount Raised",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (_, { amount }) => (
        <div>
          <span>{amount || "-"}</span>
        </div>
      ),
    },
    {
      title: "Booking Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      render: (_, { createdAt }) => (
        <div>
          <span>{moment(createdAt).format("MMM Do YYYY, h:mm a") || "-"}</span>
        </div>
      ),
    },
  ];

  const getFundRaisingBooking = async (page: number) => {
    try {
      setLoading(true);
      const res = await funRaisingBookingQuery({
        eventId,
        page: currentPage,
        size: 10,
        search: query || "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setBooking(res?.data?.booking);

        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [debouncedQuery]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getFundRaisingBooking(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    if (eventId) {
      getFundRaisingBooking(1);
    }
    getFundRaisingBookingDetailsById();
  }, [eventId]);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Fund Raising Event Details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/fund-raising", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              {/* <Col xs={24} md={12}>
              <h4>Profile image</h4>
              <div
                style={{
                  display: "flex",
                }}
              >
                <img
                  style={{
                    height: 100,
                    // margin: "auto",
                  }}
                  src="/static/images/profile.png"
                  alt=""
                />
              </div>
            </Col> */}
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Event Name</h4>
                <h5>{fundRaisingData?.name}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Event Description</h4>
                <h5>{fundRaisingData?.description || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Event Date Time</h4>
                <h5>
                  {moment(fundRaisingData?.createdAt).format(
                    "MMM Do YYYY, h:mm a"
                  ) || "-"}
                </h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Money Goal</h4>
                <h5>{fundRaisingData?.moneyGoal || "-"}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Total Fund Collected</h4>
                <h5>{fundRaisingData?.totalAmountCollected || "-"}</h5>
              </Col>
            </Row>
          </div>
        </Card>
        <div style={{ marginTop: 25 }}>
          <Table
            className="tableSelector"
            columns={columns}
            dataSource={booking}
            rowKey="_id"
            pagination={pagination}
          />
        </div>
      </Spin>
    </div>
  );
};

export default FundRaisingDetails;
