import { Card, Col, Input, Row, Button, Space } from "antd";

import { DownloadOutlined, SearchOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

const UsersReport = () => {
  interface DataType {
    key: string;
    points: string;
    score: string;
    nunberOfTokens: string;
    winners: string;
    rank: string;
    name: string;
    username: string;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Player Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      align: "center",
    },
    {
      title: "Player Ranking",
      key: "rank",
      dataIndex: "rank",
      align: "center",
      width: "10%",
    },
    {
      title: "Player Points",
      dataIndex: "points",
      key: "points",
      align: "center",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      align: "center",
    },
    {
      title: "Winning Matches",
      dataIndex: "winners",
      key: "winners",
      align: "center",
    },
    {
      title: "Tokens",
      dataIndex: "nunberOfTokens",
      key: "nunberOfTokens",
      align: "center",
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: "John Doe",
      username: "doe45",
      points: "7878",
      score: "3",
      nunberOfTokens: "133",
      winners: "3",
      rank: "#3",
    },
    {
      key: "2",
      name: "Will Smith",
      username: "doe45",
      points: "7878",
      score: "5",
      nunberOfTokens: "219",
      winners: "2",
      rank: "#8",
    },
    {
      key: "3",
      name: "Robert Downy",
      username: "doe45",
      points: "7878",
      score: "2",
      nunberOfTokens: "145",
      winners: "4",
      rank: "#4",
    },
    {
      key: "4",
      name: "Jim Carry",
      username: "doe45",
      points: "7878",
      score: "3",
      nunberOfTokens: "205",
      winners: "4",
      rank: "#4",
    },
    {
      key: "5",
      name: "John Doe",
      username: "doe45",
      points: "7878",
      score: "5",
      nunberOfTokens: "50",
      winners: "2",
      rank: "#6",
    },
    {
      key: "6",
      name: "Richard",
      username: "doe45",
      points: "7878",
      score: "1",
      nunberOfTokens: "100",
      winners: "3",
      rank: "#6",
    },
  ];

  return (
    <div style={{}}>
      <div className="srch">
        <Row
          style={{ justifyContent: "space-between", padding: "15px 0px" }}
          gutter={16}
        >
          <Col xs={24} md={7}>
            <Input
              placeholder="Search Users"
              prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
              style={{ height: 50, borderRadius: 10 }}
            />
          </Col>
          <Col
            style={{
              margin: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              icon={<DownloadOutlined />}
              style={{
                background: "#ECAC35",
                height: 35,
                color: "black",
              }}
              type="primary"
            >
              Export CSV
            </Button>
          </Col>
        </Row>

        <Table columns={columns} dataSource={data} rowKey="_id" />
      </div>
    </div>
  );
};

export default UsersReport;
