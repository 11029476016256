import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { RcFile } from "antd/es/upload";

import "react-phone-input-2/lib/style.css";
import { ContentHeader, MultipleImages } from "../../../components";
import {
  usePutUpdateShopsMutation,
  useLazyGetShopDetailsIdQuery,
} from "../../../services/shops";
import { errorToast, successToast } from "../../../helpers";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { UploadMedia } from "../../../utils/mediaUpload";
import { CloseCircleOutlined } from "@ant-design/icons";

const EditShopForm = () => {
  const { id } = useParams();
  console.log(id, "ID");
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [getShopDetails] = useLazyGetShopDetailsIdQuery();
  const [updateShop] = usePutUpdateShopsMutation();
  const [categoryName, setCategoryName] = useState("");
  const [imageObject, setImageObject] = useState<RcFile[] | number[] | any>([]);
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const [getImages, setGetImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const getShopDetailById = async () => {
    try {
      setLoading(true);
      const res = await getShopDetails(id).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          image: res?.data?.images,
          name: res?.data?.name,
        });
        setCategoryName(res?.data?.name);
        setGetImages(res?.data?.images);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleImageDelete = (index: number) => {
    let previewImages = imagePreview.filter((_, i) => i !== index);
    setImagePreview(previewImages);
    let objectImages = (imageObject as any).filter(
      (_: any, i: number) => i !== index
    );
    setImageObject(objectImages as any);
  };

  const handleGetImageDelete = (index: number) => {
    let previewImages = getImages.filter((_, i) => i !== index);
    setGetImages(previewImages);
  };

  const handleImageUpload = async (file: RcFile) => {
    setLoading(true);
    try {
      const res = await UploadMedia(file);
      setLoading(false);
      if (res?.statusCode === 200) {
        return res?.data;
      }
      return 0;
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      return 0;
    }
  };
  const onFinish = async (values: any) => {
    if (!imageObject?.length && !getImages?.length) {
      errorToast("Please provide images/videos for event.");

      return;
    }

    let images = [] as any;
    let urls = [] as any;
    if (imageObject?.length) {
      await Promise.all(
        imageObject?.map(async (item: RcFile) => {
          if (typeof item !== "number") {
            let result = await handleImageUpload(item);
            console.log(result);
            images?.push({
              image: result,
            });
          } else {
            images?.push({
              image: item,
            });
          }
        })
      );
      urls = images.map((item: any) => item.image);
    }

    const data = {
      images: Array.from(getImages).concat(urls),
      name: values.name,
    };
    console.log(data, "cdata");

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await updateShop({
        encryptedBody,
        shopId: id,
      }).unwrap();

      if (res?.statusCode === 200) {
        successToast(res?.message);
        navigate("/manage-shops", { replace: true });
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getShopDetailById();
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manage-shops"
        heading="Edit Shops"
      />
      <Spin spinning={loading}>
        <Card>
          <div>
            <Form
              name="basic"
              layout="vertical"
              form={form}
              style={{
                maxWidth: "100%",
                marginTop: 10,
              }}
              initialValues={{ remember: true }}
              autoComplete="off"
              onFinish={onFinish}
            >
              <h3>Add images, and videos for the product</h3>
              <Row gutter={16}>
                <Col xs={24} md={24}>
                  <Row gutter={16}>
                    {getImages?.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleGetImageDelete(index)}
                        className="img_contnr"
                      >
                        <CloseCircleOutlined
                          style={{
                            color: "red",
                            position: "absolute",
                            right: 4,
                            top: 4,
                            fontSize: 20,
                            cursor: "pointer",
                          }}
                        />
                        {item ? (
                          <figure>
                            {/* @ts-ignore */}
                            {item?.split(".").pop() === "mp4" ? (
                              <video muted src={item} autoPlay />
                            ) : (
                              <img alt="" src={item} />
                            )}
                          </figure>
                        ) : null}
                      </div>
                    ))}
                    {imagePreview?.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => handleImageDelete(index)}
                        className="img_contnr"
                      >
                        <CloseCircleOutlined
                          style={{
                            color: "red",
                            position: "absolute",
                            right: 4,
                            top: 4,
                            fontSize: 20,
                            cursor: "pointer",
                          }}
                        />
                        <figure>
                          <img alt="" src={item} />
                        </figure>
                      </div>
                    ))}
                  </Row>

                  <div className="img_contnr2">
                    <MultipleImages
                      imageObject={imageObject}
                      setImageObject={setImageObject}
                      imagePreview={imagePreview}
                      setImagePreview={setImagePreview}
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your name!",
                        whitespace: true,
                      },
                      {
                        min: 3,
                        message: "Name must be at least 3 characters",
                      },
                    ]}
                  >
                    <div>
                      <h4>Category Name</h4>
                      <Input
                        placeholder="Full Name"
                        style={{ height: 55 }}
                        value={categoryName}
                        onChange={(e) => {
                          setCategoryName(e.target.value);
                        }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      background: "#C69320",
                      width: 200,
                      height: 50,

                      margin: "auto",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default EditShopForm;
