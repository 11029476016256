import { END_POINTS } from "../helpers";
import { CommonBody, DashboardTypes } from "../types/General";
import { SubAdmins } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PutBlockSubAdminParams = {
  subAdminId: string;
  encryptedBody: CommonBody;
};
type SubAdminRoleParams = {
  subadmin_id: string;
  encryptedBody: CommonBody;
};

type PostAddSubAdminResponse = {
  statusCode: number;
  data?: SubAdmins;
  message: string;
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubAdminDetails: builder.query<
      CommonResponse,
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.subAdmin +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    getSubAdminDetailsId: builder.query<CommonResponse, string | undefined>({
      query: (subAdminId) => ({
        url: `${END_POINTS.subAdmin}/${subAdminId}`,
        method: "GET",
      }),
    }),

    deleteSubAdmin: builder.mutation<
      { statusCode: number },
      { subAdminId: string }
    >({
      query: ({ subAdminId }) => ({
        url: `${END_POINTS.subAdmin}/${subAdminId}`,
        method: "DELETE",
      }),
    }),
    putBlockSubAdmin: builder.mutation<CommonResponse, PutBlockSubAdminParams>({
      query: ({ encryptedBody, subAdminId }) => ({
        url: `${END_POINTS.subAdmin}/${subAdminId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    putUpdateSubAdmin: builder.mutation<
      { statusCode: number },
      PutBlockSubAdminParams
    >({
      query: ({ encryptedBody, subAdminId }: any) => ({
        url: `${END_POINTS.subAdmin}/${subAdminId}/`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    getSubAdminRole: builder.query<CommonResponse, {}>({
      query: ({}) => ({
        url: END_POINTS.subAdminRole,

        method: "GET",
      }),
    }),

    deleteSubAdminRole: builder.mutation<
      { statusCode: number },
      { roleId: string }
    >({
      query: ({ roleId }) => ({
        url: `${END_POINTS.subAdminRole}/${roleId}`,
        method: "DELETE",
      }),
    }),

    putUpdateSubAdminRole: builder.mutation<
      { statusCode: number; message: string },
      {}
    >({
      query: ({ encryptedBody, roleId }: any) => ({
        url: `${END_POINTS.subAdminRole}/${roleId}/`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    postAddSubAdminRole: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.subAdminRole,
        method: "POST",
        body,
      }),
    }),
    postAddSubAdmin: builder.mutation<PostAddSubAdminResponse, CommonBody>({
      query: (body) => ({
        url: `${END_POINTS.subAdmin}`,
        method: "POST",
        body,
      }),
    }),
    postEditSubAdmin: builder.mutation<
      PostAddSubAdminResponse,
      SubAdminRoleParams
    >({
      query: ({ encryptedBody, subadmin_id }) => ({
        url: `${END_POINTS.subAdmin}/${subadmin_id}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
  }),
});

export const {
  useLazyGetSubAdminDetailsQuery,
  usePutBlockSubAdminMutation,
  useDeleteSubAdminMutation,
  useLazyGetSubAdminDetailsIdQuery,
  usePutUpdateSubAdminRoleMutation,
  useLazyGetSubAdminRoleQuery,
  useDeleteSubAdminRoleMutation,
  usePostAddSubAdminRoleMutation,
  usePostAddSubAdminMutation,
  usePostEditSubAdminMutation,
} = categoryApi;
