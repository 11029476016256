import { Button, Card, Col, DatePicker, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ContentHeader, MultipleImages } from "../../../components";
import type { DatePickerProps, RangePickerProps } from "antd/es/date-picker";
import EditText from "../../../components/textEditor";

import { RcFile } from "antd/es/upload";

const EventBookingForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [imageObject, setImageObject] = useState<RcFile[] | number[]>([]);
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const [venderRule, setVenderRule] = useState("");
  const [bookingRule, setBookingRule] = useState("");
  const [cancellation, setCancellation] = useState("");

  // const onFinish = (values: any) => {
  //   console.log("Received values of form: ", values);
  //   navigate("/manage-event-booking", { replace: true });
  // };

  const onChange = (
    value: DatePickerProps["value"] | RangePickerProps["value"],
    dateString: [string, string] | string
  ) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  };

  const onOk = (
    value: DatePickerProps["value"] | RangePickerProps["value"]
  ) => {
    console.log("onOk: ", value);
  };

  const handleImageDelete = (index: number) => {
    let previewImages = imagePreview.filter((_, i) => i !== index);
    setImagePreview(previewImages);
    let objectImages = (imageObject as any).filter(
      (_: any, i: number) => i !== index
    );
    setImageObject(objectImages as any);
  };

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manage-event-booking"
        heading="Edit Booking"
      />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            // onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <h3>Event Details</h3>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="eventName"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input event name!",
                  //     whitespace: true,
                  //   },
                  //   {
                  //     min: 3,
                  //     message: "Name must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Event Name</h4>
                    <Input placeholder="Event Name" style={{ height: 55 }} />
                  </div>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="eventCategory"
                  // rules={[
                  //   { required: true, message: "Please input event category!" },
                  //   {
                  //     min: 3,
                  //     message: "event category must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Event Category</h4>
                    <Input
                      placeholder="Event Category"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="eventDescription"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input event name!",
                  //     whitespace: true,
                  //   },
                  //   {
                  //     min: 3,
                  //     message: "Name must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Event Description</h4>
                    <Input
                      placeholder="Event Description"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Form.Item name="dateTime">
                  <div>
                    <h4>Event Date Time</h4>
                    <DatePicker
                      style={{ height: 55, width: "100%" }}
                      showTime
                      onChange={onChange}
                      onOk={onOk}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="hostName"
                  // rules={[
                  //   { required: true, message: "Please input event category!" },
                  //   {
                  //     min: 3,
                  //     message: "event category must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Host Name</h4>
                    <Input placeholder="Host Name" style={{ height: 55 }} />
                  </div>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="location"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your Location!",
                  //     whitespace: true,
                  //   },
                  //   {
                  //     min: 3,
                  //     message: "Location must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Location</h4>
                    <Input placeholder="Location" style={{ height: 55 }} />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="totalTickets"

                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your Zip Code / Area Code!",
                  //   },
                  //   {
                  //     min: 3,
                  //     message: "Zip code must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Total Tickets</h4>
                    <Input
                      placeholder="Total Tickets"
                      style={{ height: 55 }}
                      type="number"
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="Price"

                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your Zip Code / Area Code!",
                  //   },
                  //   {
                  //     min: 3,
                  //     message: "Zip code must be at least 3 characters",
                  //   },
                  // ]}
                >
                  <div>
                    <h4>Ticket Price</h4>
                    <Input
                      placeholder="Ticket Price"
                      style={{ height: 55 }}
                      type="number"
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <h3>Images, and videos for the event</h3>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                {imagePreview?.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleImageDelete(index)}
                    className="img_contnr"
                  >
                    <CloseCircleOutlined
                      style={{
                        color: "red",
                        position: "absolute",
                        right: 4,
                        top: 4,
                        fontSize: 20,
                        cursor: "pointer",
                      }}
                    />
                    <figure>
                      <img alt="" src={item} />
                    </figure>
                  </div>
                ))}

                <div className="img_contnr2">
                  <MultipleImages
                    imageObject={imageObject}
                    setImageObject={setImageObject}
                    imagePreview={imagePreview}
                    setImagePreview={setImagePreview}
                  />
                </div>
              </Col>
            </Row>

            <h3>Event Rules</h3>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item name="vendorRules">
                  <div>
                    <h4>Vendor Rules</h4>
                    <EditText setContent={setVenderRule} content={venderRule} />
                  </div>
                </Form.Item>
              </Col>

              <Col sm={24} xs={24} md={12}>
                <Form.Item name="bookingRules">
                  <div>
                    <h4>Booking Rules</h4>
                    <EditText
                      setContent={setBookingRule}
                      content={bookingRule}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item name="cancellationRules">
                  <div>
                    <h4>Cancellation Rules</h4>
                    <EditText
                      setContent={setCancellation}
                      content={cancellation}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    navigate("/manage-event-booking", { replace: true });
                  }}
                >
                  Edit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default EventBookingForm;
