import { END_POINTS } from "../helpers";
import { CommonBody, Notification } from "../types/General";

import emptySplitApi from "../utils/rtk";

type notificationResponse = {
  statusCode: number;
  message?: string;
};

type notificationBody = {
  title: string;
  message: string;
};

type GetNotificationsResponse = {
  statusCode: number;
  code?: number;
  data?: {
    message: string;
    notificationCount: number;
    pages: number;
    notification: Notification[] | [];
  };
};

export const addNotificationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddNotification: builder.mutation<notificationResponse, CommonBody>({
      query: (body) => ({
        url: `${END_POINTS.notification}`,
        method: "POST",
        body,
      }),
    }),

    getNotifications: builder.query<GetNotificationsResponse, {}>({
      query: () => ({
        url: `${END_POINTS.notification}`,
        method: "GET",
      }),
    }),
    deleteNotification: builder.mutation<
      { statusCode: number },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.notification}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  usePostAddNotificationMutation,
  useLazyGetNotificationsQuery,
  useDeleteNotificationMutation,
} = addNotificationApi;
