import { ContentHeader } from "../../components";
import ProductCategoryList from "./category";

const ProductCategories = () => {
  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Product Categories" />
      <ProductCategoryList />
    </div>
  );
};

export default ProductCategories;
