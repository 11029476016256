import { useEffect, useState } from "react";
import { Card, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";
import ActiveBookings from "./ActiveBookings";
import UpcomingBookings from "./UpcomingBooking";
import OldBookings from "./OldBookings";
import {
  useLazyGetAllEventBookingsQuery,
  useLazyGetAllEventsQuery,
} from "../../services/events";
import { EventBooking, Events } from "../../types/General";
import { errorToast } from "../../helpers";

const ManageEventBooking = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<string | undefined>();
  const [eventsList, setEventsList] = useState<Events[]>([]);
  const [query, setQuery] = useState("");
  const [bookingDetail, setBookingDetail] = useState<EventBooking[]>();
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [getEventsMethod] = useLazyGetAllEventsQuery();
  const [getEventBookingMethod] = useLazyGetAllEventBookingsQuery();

  const onChange = (key: string) => {
    setActiveTab(key);
    setSelectedEvent(undefined);
    setBookingDetail([]);
    setCurrentPage(1);
  };

  const getAllEventsData = async () => {
    try {
      const res = await getEventsMethod({
        query: "",
      }).unwrap();

      if (res?.statusCode === 200) {
        setEventsList(res?.data?.event || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const getBookingDetail = async (page: number) => {
    try {
      setLoading(true);
      const res = await getEventBookingMethod({
        page,
        query,
        size: 10,
        activeTab: Number(activeTab),
        eventId: selectedEvent,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setBookingDetail(res?.data?.booking || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Active Bookings`,
      children: (
        <ActiveBookings
          eventsList={eventsList}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          setQuery={setQuery}
          totalCount={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          getBookingDetail={getBookingDetail}
          bookingDetail={bookingDetail}
        />
      ),
    },
    {
      key: "2",
      label: `Upcoming Bookings`,
      children: (
        <UpcomingBookings
          eventsList={eventsList}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          setQuery={setQuery}
          totalCount={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          getBookingDetail={getBookingDetail}
          bookingDetail={bookingDetail}
        />
      ),
    },
    {
      key: "3",
      label: "Old Bookings",
      children: (
        <OldBookings
          eventsList={eventsList}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          setQuery={setQuery}
          totalCount={totalCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          getBookingDetail={getBookingDetail}
          bookingDetail={bookingDetail}
        />
      ),
    },
  ];

  useEffect(() => {
    getAllEventsData();
  }, []);

  useEffect(() => {
    getBookingDetail(1);
  }, [query, activeTab, selectedEvent]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Manage Event Booking" />
      <Card style={{ overflowX: "scroll" }}>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </Card>
    </div>
  );
};

export default ManageEventBooking;
