import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PutUserParams = {
  ownerId: string | undefined;
  encryptedBody: CommonBody;
};

export const categoryApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getOwnerDetails: builder.query<
      CommonResponse,
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.owner +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    getOwnerDetailsId: builder.query<CommonResponse, string | undefined>({
      query: (ownerId) => ({
        url: `${END_POINTS.owner}/${ownerId}`,
        method: "GET",
      }),
    }),
    postAddOwner: builder.mutation<CommonResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.owner,
        method: "POST",
        body,
      }),
    }),
    putBlockOwner: builder.mutation<CommonResponse, PutUserParams>({
      query: ({ encryptedBody, ownerId }) => ({
        url: `${END_POINTS.owner}/${ownerId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    putUpdateOwner: builder.mutation<CommonResponse, PutUserParams>({
      query: ({ encryptedBody, ownerId }) => ({
        url: `${END_POINTS.owner}/${ownerId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    putUpdateOwnerRequest: builder.mutation<CommonResponse, PutUserParams>({
      query: ({ encryptedBody, ownerId }) => ({
        url: `${END_POINTS.owner}/${ownerId}`,
        method: "PUT",
        body: encryptedBody,
      }),
    }),
    deleteOwner: builder.mutation<CommonResponse, { ownerId: string }>({
      query: ({ ownerId }) => ({
        url: `${END_POINTS.owner}/${ownerId}`,
        method: "DELETE",
      }),
    }),
    getOwnerCsv: builder.query<CommonResponse, {}>({
      query: ({}) => ({
        url: `${END_POINTS.getOwnerCsv}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetOwnerDetailsQuery,
  useLazyGetOwnerDetailsIdQuery,
  usePostAddOwnerMutation,
  usePutBlockOwnerMutation,
  useDeleteOwnerMutation,
  usePutUpdateOwnerMutation,
  usePutUpdateOwnerRequestMutation,
  useLazyGetOwnerCsvQuery,
} = categoryApi;
