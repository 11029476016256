import { Button, Card, Col, Row, Space, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ManageEvents from "../manageEvent";
import { useEffect, useState } from "react";
import { useLazyGetOwnerDetailsIdQuery } from "../../../services/owner";
import EventTable from "../manageEvent";

const OwnerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ownerDetails, setOwnerDetails] = useState();
  const [query, setQuery] = useState("");

  const [getOwnerDataById, getOwnerDataByIdQuery] =
    useLazyGetOwnerDetailsIdQuery();

  const getOwnerDetailsById = async () => {
    try {
      const res = await getOwnerDataById(id).unwrap();

      if (res?.statusCode === 200) {
        setOwnerDetails(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOwnerDetailsById();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Card loading={getOwnerDataByIdQuery.isLoading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>Owner details</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage-event-owner", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Row gutter={16}>
            <Col sm={24} xs={24} md={12}>
              <h4>Owner image</h4>
              <div
                style={{
                  display: "flex",
                }}
              >
                <img
                  style={{
                    height: 100,
                    width: 100,
                    // margin: "auto",
                    borderRadius: 50,
                  }}
                  src={ownerDetails?.image || "/static/images/dummy.png"}
                  alt=""
                />
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Owner Name</h4>
              <h5>
                {ownerDetails?.firstName || "-"} {ownerDetails?.lastName}
              </h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Owner Earnings</h4>
              <h5>{ownerDetails?.earning || "-"}</h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Email</h4>
              <h5>{ownerDetails?.email || "-"}</h5>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Phone Number</h4>
              <h5>
                {ownerDetails?.countryCode || "-"} {ownerDetails?.phone}
              </h5>
            </Col>

            <Col xs={20} md={8}>
              <h4>Request Status</h4>
              <h5>
                {ownerDetails?.docsApproved
                  ? ownerDetails.docsApproved.charAt(0).toUpperCase() +
                    ownerDetails.docsApproved.slice(1).toLowerCase()
                  : "-"}
              </h5>
            </Col>
            <Col xs={20} md={8}>
              <h4>Document Name</h4>
              <h5>{ownerDetails?.docName || "-"}</h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={20} md={8}>
              <h4>Document Front Image</h4>
              {ownerDetails?.docFrontImage ? (
                <img
                  style={{
                    height: 200,
                    width: 200,
                  }}
                  src={ownerDetails?.docFrontImage}
                  alt=""
                />
              ) : (
                <h5>Not found</h5>
              )}
            </Col>
            <Col xs={20} md={8}>
              <h4>Document Back Image</h4>
              {ownerDetails?.docBackImage ? (
                <img
                  style={{
                    height: 200,
                    width: 200,
                  }}
                  src={ownerDetails?.docBackImage}
                  alt=""
                />
              ) : (
                <h5>Not found</h5>
              )}
            </Col>
          </Row>
        </div>
      </Card>
      <h3>Event Details</h3>
      <Card>
        <EventTable
          ownerId={id}
          // totalCount={totalCount}
          getAllEventsData={getOwnerDetailsById}
          // currentPage={currentPage}
          // setCurrentPage={setCurrentPage}
          allEvents={ownerDetails?.events}
          // loading={loading}
          // setLoading={setLoading}
          query={query}
          setQuery={setQuery}
        />
      </Card>
    </div>
  );
};

export default OwnerDetails;
