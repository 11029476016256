import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { SetStateAction, useEffect, useState } from "react";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { ContentHeader } from "../../../components";
import EditText from "../../../components/textEditor";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { errorToast, successToast } from "../../../helpers";
import {
  useLazyGetPlanDetailsIdQuery,
  usePostAddPlanMutation,
  usePutUpdatePlanMutation,
} from "../../../services/memberships";

const MembershipForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedValidity, setSelectedValidity] = useState("");
  const [price, setPrice] = useState<number>();
  const { id } = useParams();

  // const [feature, setFeature] = useState(id !== "add" ? "" : " ");
  const [selectedFeature, setSelectedFeature] = useState<boolean>(true);
  const [selectedValue, setSelectedValue] = useState<boolean>(false);

  const [values, setValues] = useState({
    planName: "",
    description: "",
  });

  const [numberOfProductPosts, setNumberOfProductPosts] = useState<number>(10);
  const [freePlan, setFreePlan] = useState(false);
  const [addPlan, addPlanMutation] = usePostAddPlanMutation();
  const [updatePlan, updatePlanMutation] = usePutUpdatePlanMutation();
  const [getPlanDetailsById, getPlanDetailsByIdQuery] =
    useLazyGetPlanDetailsIdQuery();

  const { Option } = Select;

  const validity = [
    { name: "3 Months" },
    { name: "6 Months" },
    { name: "9 Months" },
    { name: "1 Year" },
  ];

  const PlanDetailsByIdHandler = async () => {
    try {
      const res = await getPlanDetailsById(id).unwrap();

      if (res?.statusCode === 200) {
        form.setFieldsValue({
          price: res?.data?.price,
          validity: res?.data?.validity,
          planName: res?.data?.planName,
          description: res?.data?.description,
          feature: res?.data?.feature,
          numberOfProductPosts: res?.data?.noOfPostsAllowed,
        });
        setValues({
          ...values,
          planName: res?.data?.planName || "",
          description: res?.data?.description || "",
        });

        if (res?.data?.type === "FREE") {
          setFreePlan(true);
        } else {
          setFreePlan(false);
        }
        setPrice(res?.data?.price);
        setSelectedValidity(res?.data?.validity);
        setNumberOfProductPosts(res?.data?.noOfPostsAllowed);
        if (res?.data?.feature === 1) {
          setSelectedFeature(true);
        } else if (res?.data?.feature === 2) {
          setSelectedValue(true);
        } else {
          setSelectedValue(true);
          setSelectedFeature(true);
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const data = {
      planName: values.planName,
      price: values.price,
      validity: selectedValidity || "",
      description: values.description,
      noOfPostsAllowed: numberOfProductPosts,
      feature:
        selectedFeature && !selectedValue
          ? 1
          : !selectedFeature && selectedValue
          ? 2
          : 3,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      let res;
      id === "add"
        ? (res = await addPlan(encryptedBody).unwrap())
        : (res = await updatePlan({
            encryptedBody,
            planId: id,
          }).unwrap());

      if (res?.statusCode === 200) {
        navigate("/manage-membership-plans", { replace: true });
        successToast(
          id === "add" ? "Plan added successfully" : "Plan updated successfully"
        );
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleChangeForValidity = (value: any) => {
    form.setFieldsValue({
      validity: value,
    });
    setSelectedValidity(value);
    form.validateFields(["validity"]);
  };

  useEffect(() => {
    if (id !== "add") {
      PlanDetailsByIdHandler();
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        buttonText="Back"
        buttonNavigateTo="manage-membership-plans"
        heading={`${id === "add" ? "Add" : "Edit"} Plan`}
      />
      <Card loading={getPlanDetailsByIdQuery.isLoading}>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              maxWidth: "100%",
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="planName"
                  rules={[
                    {
                      required: true,
                      message: "Please input plan name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Plan Name</h4>
                    <Input
                      maxLength={20}
                      value={values?.planName}
                      onChange={(e) => {
                        setValues({ ...values, planName: e.target.value });
                      }}
                      placeholder="Plan Name"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              {freePlan ? null : (
                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    name="price"
                    rules={[
                      {
                        required: freePlan ? false : true,
                        message: "Please input price!",
                      },
                      {
                        pattern: /^[0-9]*$/,
                        message: "Please enter only numeric values",
                      },
                    ]}
                  >
                    <div>
                      <h4>Price</h4>
                      <Input
                        maxLength={10}
                        value={price}
                        onChange={(e) => {
                          const input = Number(e.target.value);
                          if (!isNaN(input)) {
                            setPrice(input);
                          }
                        }}
                        placeholder="Price"
                        style={{ height: 55 }}
                      />
                    </div>
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row gutter={16}>
              {freePlan ? null : (
                <Col sm={24} xs={24} md={12}>
                  <Form.Item
                    name="validity"
                    rules={[
                      {
                        required: freePlan ? false : true,
                        message: "Please select plan validity!",
                      },
                    ]}
                  >
                    <div>
                      <h4>Validity</h4>
                      <Select
                        allowClear
                        maxLength={20}
                        className="select_type"
                        onChange={(val) => handleChangeForValidity(val)}
                        value={selectedValidity ? selectedValidity : undefined}
                        style={{ height: 55, width: "100%" }}
                        placeholder="Validity"
                      >
                        {validity?.map((item, index) => (
                          <Option
                            key={index}
                            value={item?.name}
                            label={item?.name}
                          >
                            {item?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Form.Item>
                </Col>
              )}
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input plan description!",
                    },
                    {
                      min: 10,
                      message: "Description must be at least 10 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Description</h4>
                    <Input
                      value={values?.description}
                      onChange={(e) => {
                        setValues({ ...values, description: e.target.value });
                      }}
                      placeholder="Description"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item name="feature">
                  <div>
                    <h4>Add Feature</h4>

                    <div>
                      <Checkbox
                        disabled={selectedFeature}
                        checked={selectedFeature}
                        // disabled={!numberOfProductPosts}
                        onChange={() => {
                          // setSelectedFeature(!selectedFeature);
                          // if (selectedFeature) {
                          //   // setNumberOfProductPosts(0);
                          // }
                        }}
                      >
                        Number of Product Posts
                      </Checkbox>
                      <Form.Item
                        name="numberOfProductPosts"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              // Check if the value is explicitly set by the user and is not equal to the default (10)
                              if (value !== undefined && value !== 10) {
                                if (!value || value === 0) {
                                  return Promise.reject(
                                    "Please input number of product posts!"
                                  );
                                }
                              }
                              return Promise.resolve();
                            },
                          }),
                          {
                            pattern: /^[0-9]*$/,
                            message: "Please enter only numeric values",
                          },
                        ]}
                      >
                        <div>
                          <Input
                            maxLength={4}
                            style={{ height: 55, margin: "10px 0" }}
                            placeholder="Enter number of product posts"
                            onChange={(e) => {
                              const input = Number(e.target.value);
                              if (!isNaN(input)) {
                                setNumberOfProductPosts(input);
                              }
                            }}
                            value={numberOfProductPosts}
                          />
                        </div>
                      </Form.Item>

                      <Checkbox
                        disabled={freePlan}
                        checked={selectedValue}
                        onChange={() => setSelectedValue(!selectedValue)}
                      >
                        Create Shop
                      </Checkbox>
                    </div>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <div style={{ marginTop: 30 }}>
                <Button
                  loading={
                    addPlanMutation.isLoading || updatePlanMutation.isLoading
                  }
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default MembershipForm;
