import { END_POINTS } from "../helpers";
import { CommonBody, Reviews } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponse = {
  statusCode: number;
  data?: {
    count: number;
    pages: number;
    data: Reviews[] | [];
  };
  message: string;
};

type ReplyToPostParams = {
  reviewId: string | undefined;
  encryptedBody: CommonBody;
};

export const supportApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getReview: builder.query<
      CommonResponse,
      { page: number; activeTab: number; search: string }
    >({
      query: ({ page, activeTab, search }) => ({
        url:
          END_POINTS.review +
          "?type=" +
          activeTab +
          "&page=" +
          page +
          "&size=10" +
          "&search=" +
          search,

        method: "GET",
      }),
    }),

    postReplyToPost: builder.mutation<CommonResponse, ReplyToPostParams>({
      query: ({ encryptedBody, reviewId }) => ({
        url: `${END_POINTS.replyToReview}/${reviewId}`,
        method: "POST",
        body: encryptedBody,
      }),
    }),

    deleteReview: builder.mutation<CommonResponse, { reviewId: string }>({
      query: ({ reviewId }) => ({
        url: `${END_POINTS.review}/${reviewId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetReviewQuery,
  usePostReplyToPostMutation,
  useDeleteReviewMutation,
} = supportApi;
