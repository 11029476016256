import { useEffect, useState } from "react";
import { Card, Tabs, TabsProps } from "antd";
import { ContentHeader } from "../../components";
import Complaints from "./Complaints";
import BookingQueries from "./BookingQueries";
import Queries from "./Queries";
import { useLazyGetReviewQuery } from "../../services/support";
import { errorToast } from "../../helpers";
import { Reviews } from "../../types/General";

const CustomerSupport = () => {
  const [getReviewData] = useLazyGetReviewQuery();
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [allReview, setAllReview] = useState<Reviews[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState("");

  const onChange = (key: string) => {
    setActiveTab(key);
  };

  const getAllReviewsData = async (page: number) => {
    try {
      setLoading(true);
      const res = await getReviewData({
        page,
        activeTab: Number(activeTab),
        search: query,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setAllReview(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Queries Received`,
      children: (
        <Queries
          hidePermission={{
            isAdd: true,
            isDelete: true,
          }}
          allReview={allReview}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllReviewsData={getAllReviewsData}
        />
      ),
    },
    {
      key: "2",
      label: `Complaints/Disputes`,
      children: (
        <Complaints
          hidePermission={{
            isAdd: true,
            isDelete: true,
          }}
          allReview={allReview}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllReviewsData={getAllReviewsData}
        />
      ),
    },
    {
      key: "3",
      label: `Booking Related Disputes`,
      children: (
        <BookingQueries
          hidePermission={{
            isAdd: false,
            isDelete: false,
          }}
          allReview={allReview}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          setLoading={setLoading}
          query={query}
          setQuery={setQuery}
          totalCount={totalCount}
          getAllReviewsData={getAllReviewsData}
        />
      ),
    },
  ];

  useEffect(() => {
    getAllReviewsData(1);
  }, [activeTab, query]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Customer Support" />
      <Card style={{ overflowX: "scroll" }}>
        <Tabs
          activeKey={activeTab}
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
        />
      </Card>
    </div>
  );
};

export default CustomerSupport;
