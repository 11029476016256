import AuthWrapper from "../../../layout/authWrapper";
import "./login.css";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";

import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  successToast,
} from "../../../helpers";
import { useAppDispatch } from "../../../hooks/store";

import { useEffect, useState } from "react";
import { usePostLoginMutation } from "../../../services/auth";
import { setCredentials } from "../../../reducer/authSlice";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const Login = () => {
  const [postLogin, postLoginData] = usePostLoginMutation();
  const [isRemember, setIsRemember] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const fcmToken = await getFromStorage(STORAGE_KEYS.fcmToken);
    const data = {
      key: values.email,
      password: values.password,
      deviceType: "WEB",
      deviceToken: fcmToken || "abcdef123456",
    };
    console.log(data, "bodyyyyy");

    if (isRemember) {
      setToStorage(STORAGE_KEYS.credentials, JSON.stringify(data));
    } else {
      await removeFromStorage(STORAGE_KEYS.credentials);
    }

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await postLogin(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        setToStorage(STORAGE_KEYS.token, JSON.stringify(res?.data?.token));
        setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
        dispatch(
          setCredentials({
            user: res?.data || null,
            token: res?.data?.token || null,
          })
        );
        navigate("/dashboard", { replace: true });
        successToast("Login Successfully");
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message);
    }
  };

  const onSelectChange = async () => {
    const creds = getFromStorage(STORAGE_KEYS.credentials);
    console.log(creds, "creds");

    if (creds) {
      const credentials = await JSON.parse(`${creds}`);
      form.setFieldsValue({
        email: credentials?.key,
        password: credentials?.password,
      });
      setIsRemember(true);
    }
  };

  useEffect(() => {
    onSelectChange();
  }, []);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="login_img">
          {/* <img src="/static/images/logo.png" alt="" /> */}
        </div>
        <div className="bck_arrw_div">
          <h3>Dasooq Admin Panel</h3>
        </div>
        <div className="bck_arrw_div">
          <h3>Login</h3>
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          style={{
            marginTop: 50,
            // display: "flex",
            justifyContent: "center",
          }}
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "The input is not valid email!",
              },
            ]}
          >
            <Input
              style={{ height: "50px" }}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item
            // label="Password"

            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              style={{  height: "50px" }}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Checkbox
                onChange={() => setIsRemember(!isRemember)}
                checked={isRemember}
                style={{ color: "#000", fontWeight: 600 }}
              >
                Remember me
              </Checkbox>
              <div
                style={{ cursor: "pointer", color: "#000", fontWeight: 600 }}
                onClick={() => {
                  navigate("/forgotpassword");
                }}
              >
                Forgot password?
              </div>
            </div>
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#FEA500",
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={postLoginData.isLoading}
              >
                Login
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default Login;
