import { Button, Col, Modal, Row, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditFilled, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import moment from "moment";
import { Events } from "../../../types/General";
import { errorToast, successToast } from "../../../helpers";
import { useDeleteEventsMutation } from "../../../services/events";

const { confirm } = Modal;

interface props {
  type: string;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalCount: number | undefined;
  getAllEventsData: () => void;
  allEvents: Events[] | [];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  query: string;
  ownerId: string;
  setQuery: Dispatch<SetStateAction<string>>;
}

const EventTable = ({
  type,
  currentPage,
  setCurrentPage,
  totalCount,
  getAllEventsData,
  allEvents,
  loading,
  setLoading,
  query,
  setQuery,
  ownerId,
}: props) => {
  const navigate = useNavigate();

  // const [debouncedQuery, setDebouncedQuery] = useState("");

  const [deleteEventMethod] = useDeleteEventsMutation();

  // const handlePageChange = (page: number) => {
  //   setCurrentPage(page);
  //   getAllEventsData(page);
  // };

  // const pagination = {
  //   pageSize: 10,
  //   current: currentPage,
  //   total: totalCount,
  //   onChange: handlePageChange,
  // };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteEventMethod({ eventId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Event deleted successfully");
        getAllEventsData();
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this event?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("OK");
        handleDelete(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<Events> = [
    {
      title: "Event Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Event Category",
      dataIndex: "eventCategory",
      key: "eventCategory",
      align: "center",
      render: (_, { eventCategoryId }) => (
        <div>
          <span>{eventCategoryId?.name || "-"}</span>
        </div>
      ),
    },
    // {
    //   title: "Host Name",
    //   dataIndex: "hostName",
    //   key: "hostName",
    //   align: "center",
    // },
    {
      title: "Event Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Event Date & Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      render: (_, { time }) => (
        <div>
          <span>{moment(time).format("MMM Do YYYY, h:mm a")}</span>
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, { _id, status, isBooking }) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(
                `/manage-event-owner/event-details/${_id}/owner/${ownerId}`
              );
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>

          <>
            {status === "Upcoming" || (status === "Active" && !isBooking) ? (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `/manage-event-owner/add-event/${_id}/owner/${ownerId}`
                  );
                }}
              >
                <EditFilled style={{ color: "#C69320" }} />
              </Button>
            ) : (
              ""
            )}
            {status !== "Active" ? (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  showDeleteConfirm(_id);
                }}
              >
                <DeleteOutlined style={{ color: "#d4380d" }} />
              </Button>
            ) : (
              ""
            )}
          </>
        </Space>
      ),
    },
  ];

  // useEffect(() => {
  //   const delay = 1000; // Debounce delay in milliseconds
  //   const timerId = setTimeout(() => {
  //     setQuery(debouncedQuery);
  //   }, delay);

  //   return () => {
  //     clearTimeout(timerId); // Clear the timeout on cleanup
  //   };
  // }, [debouncedQuery]);

  return (
    <div>
      <Row
        style={{ justifyContent: "space-between", padding: "10px 0px" }}
        gutter={16}
      >
        <Col xs={24} md={7}>
          {/* <Input
            placeholder="Search Events"
            prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
            style={{ height: 50, borderRadius: 10 }}
            value={debouncedQuery}
            onChange={(e) => setDebouncedQuery(e.target.value)}
            maxLength={20}
          /> */}
        </Col>
        <Col
          style={{
            margin: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-event-owner/add-event/add/owner/${ownerId}`);
            }}
            style={{
              background: "#ECAC35",
              height: 35,
              color: "black",
            }}
            type="primary"
          >
            Add Event
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={allEvents}
        // pagination={pagination}
        rowKey="_id"
        // loading={loading}
      />
    </div>
  );
};

export default EventTable;
