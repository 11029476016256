import { useEffect, useState } from "react";
import {
  UserOutlined,
  TeamOutlined,
  PieChartOutlined,
  LineChartOutlined,
  SolutionOutlined,
  SettingOutlined,
  FormOutlined,
  SwitcherOutlined,
  BellOutlined,
  AlignLeftOutlined,
  LikeOutlined,
  PicLeftOutlined,
  ContainerOutlined,
  UsergroupAddOutlined,
  HeartOutlined,
  GlobalOutlined,
  DollarCircleOutlined,
  FlagOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import "./layout.css";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const { Sider } = Layout;

const SiderBar = ({ collapsed }: { collapsed: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAuth();

  const [selectedKey, setSelectedKey] = useState("1");
  const [list, setList] = useState<any>([]);

  const items = [
    {
      key: "1",
      icon: <PieChartOutlined />,
      label: "Dashboard",
      onClick: () => navigate("/dashboard"),
    },
    {
      key: "21",
      icon: <FileImageOutlined />,
      label: "Manage Banners",
      onClick: () => navigate("/manage-banners"),
    },
    {
      key: "2",
      icon: <TeamOutlined />,
      label: "Manage Sub-Admin",
      onClick: () => navigate("/managesubadmins"),
    },
    {
      key: "3",
      icon: <UserOutlined />,
      label: "Manage Users",
      onClick: () => navigate("/manageusers"),
    },
    {
      key: "4",
      icon: <AlignLeftOutlined />,
      label: "Listed Products Posts",
      onClick: () => navigate("/listed-products-posts"),
    },
    {
      key: "5",
      icon: <LikeOutlined />,
      label: "Featured Products",
      onClick: () => navigate("/recommended-products"),
    },
    {
      key: "6",
      icon: <PicLeftOutlined />,
      label: "Product Categories",
      onClick: () => navigate("/product-categories"),
    },
    {
      key: "7",
      icon: <PicLeftOutlined />,
      label: "Event Categories",
      onClick: () => navigate("/event-categories"),
    },
    {
      key: "8",
      icon: <PicLeftOutlined />,
      label: "Donation Categories",
      onClick: () => navigate("/donation-categories"),
    },
    {
      key: "9",
      icon: <PicLeftOutlined />,
      label: "Shop Categories",
      onClick: () => navigate("/shop-categories"),
    },
    {
      key: "10",
      icon: <UsergroupAddOutlined />,
      label: "Manage Event Owner",
      onClick: () => navigate("/manage-event-owner"),
    },
    {
      key: "11",
      icon: <FlagOutlined />,
      label: "Manage Donation Organization",
      onClick: () => navigate("/manage-organization"),
    },
    {
      key: "12",
      icon: <FormOutlined />,
      label: "Manage Shops",
      onClick: () => navigate("/manage-shops"),
    },
    {
      key: "13",
      icon: <ContainerOutlined />,
      label: "Booking Event Listing",
      onClick: () => navigate("/booking-order-management"),
    },
    {
      key: "14",
      icon: <HeartOutlined />,
      label: "Donations",
      onClick: () => navigate("/donations"),
    },
    {
      key: "15",
      icon: <DollarCircleOutlined />,
      label: "Fund Raising",
      onClick: () => navigate("/fund-raising"),
    },
    {
      key: "16",
      icon: <ContainerOutlined />,
      label: "Manage Membership Plans",
      onClick: () => navigate("/manage-membership-plans"),
    },

    {
      key: "17",
      icon: <BellOutlined />,
      label: "Notifications",
      onClick: () => navigate("/notifications"),
    },
    {
      key: "18",
      icon: <SolutionOutlined />,
      label: "Customer Support",
      onClick: () => navigate("/customersupport"),
    },

    {
      key: "19",
      icon: <FormOutlined />,
      label: "Manage CMS",
      onClick: () => navigate("/managecms"),
    },
    {
      key: "20",
      icon: <SwitcherOutlined />,
      label: "Manage FAQ's",
      onClick: () => navigate("/manage-faq"),
    },

    // {
    //   key: "18",
    //   icon: <SettingOutlined />,
    //   label: "Revenue Management",
    //   onClick: () => navigate("/revenue-management"),
    // },
  ];

  useEffect(() => {
    console.log(location.pathname);
    if (location?.pathname?.includes("dashboard")) {
      setSelectedKey("1");
    } else if (location?.pathname?.includes("managesubadmins")) {
      setSelectedKey("2");
    } else if (location?.pathname?.includes("manageusers")) {
      setSelectedKey("3");
    } else if (location?.pathname?.includes("listed-products-posts")) {
      setSelectedKey("4");
    } else if (location?.pathname?.includes("recommended-products")) {
      setSelectedKey("5");
    } else if (location?.pathname?.includes("product-categories")) {
      setSelectedKey("6");
    } else if (location?.pathname?.includes("event-categories")) {
      setSelectedKey("7");
    } else if (location?.pathname?.includes("donation-categories")) {
      setSelectedKey("8");
    } else if (location?.pathname?.includes("shop-categories")) {
      setSelectedKey("9");
    } else if (location?.pathname?.includes("manage-event-owner")) {
      setSelectedKey("10");
    } else if (location?.pathname?.includes("manage-organization")) {
      setSelectedKey("11");
    } else if (location?.pathname?.includes("/manage-shops")) {
      setSelectedKey("12");
    } else if (location?.pathname?.includes("booking-order-management")) {
      setSelectedKey("13");
    } else if (location?.pathname?.includes("donations")) {
      setSelectedKey("14");
    } else if (location?.pathname?.includes("fund-raising")) {
      setSelectedKey("15");
    } else if (location?.pathname?.includes("manage-membership-plans")) {
      setSelectedKey("16");
    } else if (location?.pathname?.includes("notifications")) {
      setSelectedKey("17");
    } else if (location?.pathname?.includes("customersupport")) {
      setSelectedKey("18");
    } else if (location?.pathname?.includes("managecms")) {
      setSelectedKey("19");
    } else if (location?.pathname?.includes("manage-faq")) {
      setSelectedKey("20");
    } else if (location?.pathname?.includes("manage-banners")) {
      setSelectedKey("21");
    } else {
      setSelectedKey("");
    }
  }, [location]);

  useEffect(() => {
    let arr = [];
    if (userData && userData?.permissions?.length) {
      const permissions = userData?.permissions;
      arr = items?.map((item) => {
        let idx = -1;
        idx = permissions?.findIndex((ele: any) => ele?.label === item?.label);
        if (idx != -1) {
          if (permissions[idx].isView) {
            return item;
          }
          return false;
        }
      });
    } else {
      arr = items;
    }
    setList(arr);
  }, [userData]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        {/* <img src={"/static/images/logo.png"} className="logo_img" alt="" /> */}
      </div>
      <Menu
        style={{ marginTop: 16 }}
        theme="dark"
        mode="inline"
        className="side_menu"
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
        items={list}
      />
    </Sider>
  );
};

export default SiderBar;
